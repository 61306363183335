// main: ../style.sass

.servants {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 80px;

    @media (max-width: 1150px) {
      justify-content: center;
    }
  }

  &__item {
    max-width: 260px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;

    &:last-child {
      margin-right: 0px;
    }

    &_img {
      min-height: 250px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &_main {
      text-align: center;
      min-height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .title {
        font-size: 24px;
        color: #000000;
      }

      & .subtitle {
        font-size: 12px;
        color: #000000;
      }
    }
  }
}

@media (max-width: 1229px) {
  .servants__wrap {
    // justify-content: space-around;
  }
}