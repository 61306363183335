// main: style.sass
*{
  box-sizing: border-box;
}
.body_wrap{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}
.wrapper{
  width: 1380px;
  margin: 0 auto;
}
.section{
  &__container{
    margin: 60px 0;
  }
  margin-bottom: 50px ;
  &:last-child{
    margin-bottom: 0;
  }
  &_title{
    font-size: 28px;
    font-weight: 600;
    color: #1e1e1e;
    margin-bottom: 30px;
    &-center{
      text-align: center;

    }
  }
}




.delivery{
  &__row{
    display: flex;
    justify-content: space-between;
    min-height: 400px;
    align-items: center;
  }
  &__box{
    margin-bottom: 60px;

  }
  &__section {
    width: 48%;
    &_li{
      display: flex;
    }
    &_icon{
      margin: 5px 10px 5px 0;
    }
    &_title{
      font-size: 30px;
      line-height: 2.37;
    }
    &_subtitle{
      font-size: 22px;
      font-weight: 600;
      margin: 15px 0;
      &-header{
        margin-top: 60px;
      }
    }
    &_text{
      font-size: 16px;
      color: #95999d;
      line-height: 1.63;
      & strong{
        font-weight: 900;
        color: #000000;
      }
    }
    &_box{
      display: flex;
      align-items: center;
      justify-content: center;

    }
    &_partment{
      border: solid 1px #e5e5e5;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;


      &_box{
        display: flex;
        align-items: baseline;
      }
      &_namber{
        font-size: 30px;
        color: #000000;
        line-height: 1;
      }
      &_text{
        font-size: 16px;
        color: #95999d;
        margin: 0 10px;
      }
    }
    &_main{
      & p{
        color: #95999d;
        font-size: 16px;
        line-height: 1.7;
        & .select_color{
          color: #e04d27;
        }
      }
    }
  }
  &__pay{
    width: 80%;
    background: #f3f3f3;
    padding: 33px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    &_img{
      margin-bottom: 25px;
      height: 30px;
      & img{
        height: 100%;
        width: auto;
      }
    }
    &_text{
      font-size: 20px;
      text-align: center;
    }
  }
}
@media  (max-width: 1250px){
  .delivery{
    &__pay{
      width: 100%;
    }
    &__section{
      &_partment{
        &_namber{
              font-size: 24px;
        }
        &_img{
          width: 100px;
          & svg{
                width: 100%;
                height: auto;
          }
        }
      }
    }
  }

}
@media  (max-width: 1150px){
  .delivery__row_colum{
    flex-direction: column;
    & .delivery__section{
      width: 100%;

      & .delivery__section_partment{
        max-width: 768px;
        margin: 30px auto;
      }
      &_img{
          width: 35%;
      }
      &_txt{
        width: 60%;
      }
    }
  }
}
@media  (max-width: 768px){
  .delivery{
    &__section{
      &_partment{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
        &_img{
          grid-column-start: 1;
          grid-column-end: 3;
          align-items: center;
          justify-content: center;
          width: 100%;
          text-align: center;
          height: 45px;
          margin-bottom: 20px;
          & svg{
            height: 100%;
          }

        }
        &_box {
          justify-content: center;
        }
      }
      &_subtitle{
        &-header{
          margin-top: 10px;
        }
      }
      &_img{
          margin: 50px 0;
      }
    }
    &__row{
      min-height: 100px;
      flex-direction: column;
      & .delivery__section{
        width: 100%;
      }
    }
    &__colum{
      &_reservs{
        flex-direction: column-reverse;
      }
    }
  }
}
@media  (max-width: 390px){
  .delivery{
    width: 92% !important;
  }
  .delivery__section_img{
    max-width: 88%;
  }
  .delivery__section_partment{
    grid-template-columns: 1fr;
    max-width: 80% !important;
    &_box{
      margin: 10px 0;
    }
    &_img{
      grid-column-end: 2;
    }
  }
}

@media  (max-width: 1400px){
  .wrapper{
    width: 100%;
    padding:  0 4%;
  }
}
