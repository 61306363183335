.privacy-policy {
  padding: 100px 0;

  h3,
  p {
    margin: 0;
  }

  a {
    color: #e04d27;
    padding: 2px;

    &:hover {
      background-color: #e04d27;
      color: #fff;
      border-radius: 2px;
    }
  }

  .section {
    padding: 20px 0;
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    color: #2f3134;
    font-size: 40px;
    line-height: 1.13;
    font-weight: 500;
    margin: 0 0 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    &-text {
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0;
      padding-left: 20px;

      li {
      }
    }
  }

  @media (max-width: 720px) {
    padding: 20px 0;

    &__title {
      margin: 0 0 10px;
      font-size: 20px;
    }

    .section {
      padding: 15px 0;

      &_title {
        font-size: 16px;
      }
    }

    &__info {
      gap: 15px;
      margin-top: 20px;

      &-text {
        font-size: 14px;
      }

      &-list {
        gap: 10px;
      }
    }
  }
}
