// main: ../style.sass
.footer__info {
  .footer__info_logo {
    display: none;
  }
}
.footer {
  background: #2a3860;
  .section {
    margin-bottom: 0px;
  }
  &__info {
    padding: 26px 0;
    display: flex;
    justify-content: space-around;

    &_text {
      color: #fff;
    }
  }
  &__wrap {
    padding: 80px 0 30px;
    display: grid;
    grid-template-columns: 22% 19% 11% 10% 10% 90px;
    justify-content: space-between;
  }
  &__group {
    &_header {
      min-height: 60px;
      display: flex;
      align-items: center;
      color: #fff;
    }
    &_main {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &_row,
    &_row a {
      font-size: 16px;
      line-height: normal;
      color: #d4d4d4;
    }
    &_coll {
      flex-direction: column;
      display: flex;
    }

    &_row {
      &-social {
        display: flex;
        justify-content: space-between;
        max-width: 90px;
      }
    }
    &_info {
      display: flex;
      justify-content: space-between;

      height: 29px;
      &-playment {
        display: grid;
        height: 29px;
        grid-template-columns: 0.7fr 1fr;
        grid-template-rows: 29px;
      }

      & .footer__info_logo {
        display: none;
      }
      &-item {
        & img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
.section__footer {
  border-top: 1px solid #fff;
  background: #2a3860;
}

@media (max-width: 1366px) {
  .footer {
    background: #2a3860;
    &__info {
      padding: 26px 0;
      display: flex;
      justify-content: space-around;
      &_text {
        color: #fff;
      }
    }
    &__wrap {
      grid-template-columns: 22% 19% 13% 10% 10% 90px;
    }
  }
}
@media (max-width: 1100px) {
  .footer {
    &__wrap {
      grid-template-columns: 35% 30% 15%;
      grid-row-gap: 30px;
    }
    &__info_logo {
      max-width: 100px;
    }
    &__group {
      &_info {
        max-width: 140px;
      }
    }
    &__group_4 {
      grid-column-start: 3;
      grid-row-start: 1;
    }
  }
}
@media (max-width: 768px) {
  .footer {
    &__wrap {
      padding: 30px 0 30px;
      align-items: center;
      grid-template-columns: 35% 110px 15%;
    }

    &__group {
      &_2,
      &_3,
      &_4 {
        display: none;
      }
      &_1 {
        & .footer__group_main {
          display: none;
        }
      }
      &_5 {
      }
      &_6 {
        & .footer__group_header {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .footer {
    &__wrap {
      display: none;
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_logo {
        display: block !important;
        margin-top: 30px;
        width: 100px;
      }
    }
  }
}
