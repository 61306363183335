// main: ../style.sass

.blog-categorie {
  margin: 30px 0 50px;

  &_title {
    font-size: 30px;
    line-height: 2;
    color: #000000;
  }

  &_item {
    position: relative;
    max-height: 600px;
    height: 100%;
    transition: 0.3s;
    overflow: hidden;

    &:hover {
      transition: 0.3s;
      transform: scale(1.03);
    }

    &-img {
      position: relative;
      max-height: 600px;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &_bg {
        position: absolute;
        top: 0;
        width: 100%;
        max-height: 600px;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
    }

    &-btn {
      display: inline-block;
      padding: 10px 30px;
      margin: 10px 0;
    }

    &-main {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &_box {
      position: absolute;
      bottom: 10%;
      width: 80%;
      left: 10%;
      max-width: 400px;
      min-height: 150px;
    }

    &-title {
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
    }

    &-text {
      font-size: 12px;
      color: #ffffff;
      margin: 10px 0;
    }

    @media (max-width: 1000px) {
      max-height: 400px;

      &-img {
        max-height: 400px;

        &_bg {
          max-height: 400px;
        }
      }
    }

    @media (max-width: 720px) {
      max-height: 300px;

      &-img {
        max-height: 300px;

        &_bg {
          max-height: 300px;
        }
      }
    }

    @media (max-width: 500px) {
      max-height: 250px;

      &-img {
        max-height: 250px;

        &_bg {
          max-height: 250px;
        }
      }
    }
  }

  &__wrap {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
}

.blog-categorie_item-main {
  & .blog-categorie_item_box {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
@media (max-width: 992px) {
  .blog-categorie {
    &_item-title {
      font-size: 33px;
    }

    &__wrap {
      margin: 20px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
}
@media (max-width: 840px) {
  .blog-categorie {
    &__wrap {
      grid-template-columns: 1fr;
    }

    &_item {
      &-main {
        grid-column-start: 1;
        grid-column-end: 2;

        & .blog-categorie_item_box {
          position: absolute;
          bottom: 10%;
          width: 80%;
          left: 10%;
          max-width: 400px;
          min-height: 150px;
          text-align: inherit;
          top: auto;
          transform: initial;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .blog-categorie {
    &_item {
      &-title {
        font-size: 25px;
      }
      &_box {
        bottom: 5%;
        width: 90%;
        left: 5%;
      }
      &-main {
        grid-column-start: 1;
        grid-column-end: 2;

        & .blog-categorie_item_box {
          bottom: 5%;
          width: 90%;
          left: 5%;
        }
      }
      &-img {
        overflow: hidden;
      }
    }
  }
}
