// main: ../style.sass

.secrch{
  margin: 30px 0;
  min-height: 600px;
  &_title{
    font-size: 30px;
      color: #000000;
      &_requesdt{
        font-size: 45px;
        font-weight: 600;
        line-height: 1.6;
        color: #2f3134;

      }
  }
  &__wrap{
    &  .catalog__all{
      margin-top: 30px;
    }

    &_container{
      &_feil{
        display: none;
        &-active{
          display: block;
        }
      }
    }
    // display: flex;
    // justify-content: space-between;
    // flex-direction: column;
  }


}
@media (max-width: 1520px) {
  .secrch{
    min-height: 500px;
  }
}
@media (max-width: 1520px) {
  .secrch{
    min-height: 400px;
  }
}
@media (max-width: 1360px) {
  .secrch{
    min-height: 300px;
  }
}
