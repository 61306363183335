// main: ../style.sass

.contact-us {
  &__form {
    // max-width: 430px;
    // min-width: 400px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr minmax(400px, 430px);
    grid-gap: 45px;
    margin-bottom: 30px;
  }

  &__section {
    &:last-child {
    }
  }

  &__map {
    width: 100%;
    height: 100%;

    & iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 992px) {
  .contact-us {
    &__wrap {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    }
    &__map {
      margin-top: 10px;
      height: 60vw;
    }
  }
}
