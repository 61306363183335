// main: ../style.sass
.body_bg{
  position: absolute;
    width: 100%;
    height: 1000%;
    z-index: 4;
    background: rgba(0,0,0,0.8);
    display: none;
    &-active{
      display: block;
    }
}
.body__navbar-active{
  overflow: hidden;
}
.header {
  height: 100px;
  background: $default-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__section {
    display: flex;
    flex-direction: row;
    width: 45%;
    align-items: center;
    justify-content: space-between;

    &_auth-wrap {

    }
    &_phone{
      display: flex;
      flex-direction: column;
    }

    &_login {
      position: relative;
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-img {
        height: 40px;
        width: 40px;

        & img {
          height: 100%;
          width: auto;
          border-radius: 50%;
        }
      }

      &-control {
        height: 30px;
        margin: 0 10px;
        cursor: pointer;
        &:hover{
          & svg{
            & path{
              transition: .3s;
              fill:#e04d27;
            }
          }
        }
        & svg {
          & path{
            transition: .3s;
            fill:#fff;
          }
          height: 100%;
          width: auto;
        }
        transition: .3s;
        &-active{
          transform: rotate(-180deg)  ;
          transition: .3s;
        }
      }
    }

    &_logout {
      display: flex;
    }

    &_search {
      margin-left: 30px;
      border-radius: 5px;
      & form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        background-color: #ffffff;
        position: relative;
        margin: 0;

        & input,
        & input:active,
        & input:focus {
          border: none;
          font-size: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          outline: none;
          background-color: #ffffff;
          position: relative;
          padding: 10px 20px;
          margin-right: 40px;
          margin-left: 5px;
        }
      }
    }

    &:nth-child(1) {
      width: 80%;
    }

    &:nth-child(2) {
      justify-content: flex-end;
      width: 40%;
    }

    &_hamburger {
      display: none;
    }
  }
}

.section__login_info {
  position: absolute;
  background: #2a3860;
  z-index: 1;
  padding: 0 15px;
  left: -55px;
  width: 216px;

  color: #fff;
  font-size: 14px;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  transition: .3s;

  &-active{
    height: 60px;
    transition: .3s;
  }


  &-footer {
    border-top: 1px solid #fff;
    margin-top: 10px;
    padding: 5px 0;
  }
}

.search__form{
  &-focus, &-blog{
    & .search__icon_wrap{
      & .next, & .blog_search-next{
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        transition: 0.7s;
      }
      & .search{
        transform: translateX(200%);
        -webkit-transform: translateX(200%);
        transition: 0.7s;
      }
      &:hover{
        cursor: pointer;
        & .next{
          & svg {
          transition: .3s;
          transform: translateX(4px);
            & path{
              transition: .3s;
              fill:#e04d27;
            }
          }
        }
      }
      & .next{
        & svg {

          & path{
            transition: .3s;

          }

      }
      & .search{
        transform: translateX(200%);
        -webkit-transform: translateX(200%);
        transition: 0.7s;
      }
    }
  }
}
}

.search__icon {
  &_wrap {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    height: 35px;
    width: 35px;
    overflow: hidden;

    transition: .3s;
    // &:hover{
    //   & .next{
    //     & svg {
    //     transition: .3s;
    //     transform: translateX(4px);
    //     }
    //   }
    // }
    & .next {
      transform: translateX(-200%);
      transition: 0.7s;
    }
    & .blog_search-next {
      transform: translateX(-200%);
      transition: 0.7s;
    }

    .search {
      transform: translateX(0%);
      transition: 0.7s;
    }

    &-focus,&-blog{
      cursor: pointer;

         & .blog_search-next{
          opacity: 1;
          -webkit-transform: translateX(0);
          transform: translateX(0);
          transition: 0.7s;
        }
        & .next {
          opacity: 1;
          -webkit-transform: translateX(0);
          transform: translateX(0);
          transition: 0.7s;
        }
        & .search{
          transform: translateX(200%);
          -webkit-transform: translateX(200%);
          transition: 0.7s;
        }
      }

  }

  &_lev {
    position: absolute;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      & .next{
        & svg {
        transition: .3s;
        transform: translateX(4px);
        }
      }
    }
    & svg {
      height: 60%;
      width: auto;
    }
  }
}

.basket {
  &__box_icon {
    display: block;
    position: relative;
    margin: 0 20px;
    & svg path{
      transition: .3s;

    }

    &:hover{
      & svg path{
        transition: .3s;
        fill:#e04d27;
        cursor: pointer;
      }
    }
  }

  &__icon {
    position: relative;
    display: block;

  }

  &__count {
    transform: translate(50%,-50%);
    position: absolute;
    top: 0;
    right: 0;
    height: 22px;
    width: 22px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    & span{
      color: #191a1d;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
@media (max-width: 1200px) {
  .header__section_search{
    max-width: 210px;
  }
}
@media (max-width: 992px) {
  .header {
    &__section {
      // &:nth-child(1) {
      //   width: 50%;
      // }
      &_search{
        max-width: 400px;

      }
      &_hamburger {
        display: block;
      }
    }
  }
  .header__section_auth-wrap-header{
    display: none;
  }
  .basket__box_icon {
    margin: 0 30px;
  }
  .navbar__wrap-active{
    .section__login_info{
      background: #3e4559;
      left: -55px;
      width: 215px;
      border-radius: 5px;
    }
  }

}
@media (max-width: 640px) {
  .header {
    &__section {
      &:nth-child(2) {

        width: 30%;
        justify-content: space-around;
      }
      &_service {
        // display: block;
        & .header__section_auth{
            display: none;
          &-wrap {
             margin: 0 10px;
         }
        }
      }
    }
  }
}
@media (max-width: 572px) {
  .header {
    &__section {
      &_service {
        // display: block;
        & .header__section_auth{
            display: none;

        }
      }
    }
  }
}
@media (max-width: 530px) {
  .header__section_logo{
    max-width: 125px;
    & img{
      width: 100%;
    }
  }
}
@media (max-width: 440px) {
  .header__section_logo{
    max-width: 125px;
    & img{
      width: 100%;
    }
  }
  .header__section_phone_item{
    font-size: 14px;
  }
}
@media (max-width: 410px) {
  .header__section{
    &:nth-child(1) {
      width: 55%;
      justify-content: space-around;
    }
    &:nth-child(2) {
      width: 40%;
      justify-content: space-around;
    }
  }
  .header__section_logo{
    max-width: 125px;
    & img{
      width: 100%;
    }
  }
  .header__section_phone{
    display: none;
  }
}
