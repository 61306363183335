// main: ../style.sass

.please {
  &__wrap {
    display: grid;
    grid-template-columns: 40%;
    grid-template-rows: repeat(2, minmax(200px, 310px));
    grid-gap: 35px;
    position: relative;
    grid-column-gap: 60px;
  }

  &__item {
    position: relative;
    overflow: hidden;
    transition: .3s;
    &:hover{

      & .please__item_main{
          transition: .3s;
        height: 190px;
      }
      & .please__item_main-horizont{
        transition: .3s;
        height: 100%;
        width: 55%;
      }
    }

    &_title {
      font-size: 25px;
      font-weight: 600;
      color: #ffffff;
    }

    &_text {
      font-size: 12px;
      color: #ececec;
    }

    &_main {
        transition: .3s;
      position: absolute;
      bottom: 0;
      height: 170px;
      width: 100%;
      background: rgba(0,0,0,0.6);
      display: flex;
      align-items: baseline;
      flex-direction: column;
      justify-content: center;
      /* padding: 50px; */
      padding: 0 40px;

      &-horizont {
        right: 0;
        width: 50%;
        height: 100%;
        padding: 25px 15px;
        justify-content: flex-end;

      }
    }

    &_text {
      color: #fff;
    }

    &_img {
      height: 100%;
      width: auto;
      position: relative;

      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-big {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;

      & .please__item_img {
        & img {
          height: 100%;
          width: auto;
          display: block;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .please {
    &__wrap {
      grid-template-columns: 45%;
      grid-column-gap: 40px;
    }

    &__item_title {
      font-size: 20px;
    }
  }
}
@media (max-width: 1100px) {
  .please {
    &__wrap {
      grid-template-columns: 45%;
      grid-column-gap: 40px;
    }

    &__item_title {
      font-size: 20px;
    }
  }
}
@media (max-width: 992px) {
  .please {
    &__wrap {
      grid-template-columns: 80%;
      grid-column-gap: 40px;
      justify-content: center;
      grid-template-rows: auto;
    }

    &__item {
      &-big {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;

        & .please__item_img img {
          width: 100%;
          height: auto;
        }
      }

      &_main {
        position: absolute;
        bottom: 0;
        height: 170px;
        width: 100%;
        background: rgba(0,0,0,0.6);
        display: flex;
        align-items: baseline;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
      }

      &_title {
        font-size: 20px;
      }

      &_img img {
        width: 105%;
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }
    }
  }
}
@media (max-width: 768px) {
  .please {
    &__wrap {
      grid-template-columns: 90%;
    }
    &__item_main {
        height: 110px;
        padding: 0 10px;
    }
  }
}
