// main: ../style.sass

.product {
  &_wrap {
  }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_section {
      width: 47%;
      &_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        & .in-stock {
          font-size: 16px;
          line-height: 1.63;
          text-align: justify;
          color: #16d303;
        }
        & .un-stock {
          font-size: 16px;
          line-height: 1.63;
          text-align: justify;
          color: red;
        }
        & .title {
          font-size: 45px;
          line-height: 1.6;
          color: #2f3134;
        }
        & .info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          width: 100%;
          flex-direction: column-reverse;
          &__price {
            display: flex;
            align-items: flex-start;
            &_wrap {
              align-items: baseline;
              display: flex;
              flex-direction: column;
              width: 100%;
            }
            &-convert {
              margin: 18px 0 5px;
              align-items: baseline;

              & .info__price_valid {
                font-size: 22px;
              }
              & span {
                color: #999999;
                font-size: 14px;
                margin-left: 5px;
              }
            }
            &_valid {
              font-size: 30px;
              font-weight: 500;
              line-height: 0.9;
              letter-spacing: normal;
              text-align: justify;
              color: #e04d27;
            }
            &_invalid {
              font-size: 22px;
              line-height: 1;
              color: #999999;
              text-decoration: line-through;
              margin: 0 15px;
            }
          }
          &__star {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 10px;
            &_wrap {
              display: flex;
              align-items: center;
              margin: 0 10px;
            }
            &_item {
              height: 16px;
              position: relative;
              & .star {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                z-index: 1;
                display: block;
                &_full {
                  display: none;
                  &-active {
                    display: block;
                    &-click {
                      display: block;
                    }
                  }
                }
              }
              &-active {
                & .star {
                  &_full {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
      &_main {
        margin: 25px 0;
      }
    }
  }
  &__group {
    &_description {
      &_title {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 3;
        color: #757575;
      }
      &_item {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-top: 2px solid #f9f8f6;
        & div {
          width: 45%;
        }
      }
      &_name {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.6;
        color: #000000;
      }
      &_value {
        font-size: 16px;
        line-height: 1.25;
        color: #757575;
      }
    }

    &_plus,
    &_minus {
      width: 38px;
      height: 38px;
      background-color: #f3f3f3;
      color: #33333a;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 600;
      line-height: 1;
      cursor: pointer;
      &:hover {
        transition: 0.3s;
        background: #e04d27;
        color: #fff;
      }
    }
  }
  &__quantity {
    display: inline-flex;
    border: solid 1px #dedede;

    &_count {
      width: 38px;
      height: 38px;
      color: #33333a;
      text-align: center;
      font-size: 18px;

      border: none;
    }
  }
  &__control {
    display: flex;
    // width: 510px;
    justify-content: space-between;
    margin: 30px 0;
    flex-wrap: wrap;
    & * {
      margin: 10px 0;
    }
    &:hover {
      cursor: pointer;
    }

    & .btn {
      max-width: 245px;
      width: 40%;
    }
  }
  &__description {
    &_wrap {
      margin: 20px 0;
    }
    &__wrap {
      display: flex;
      justify-content: space-between;
      position: relative;
      max-height: 250px;
      overflow: hidden;
      transition: 0.4s;
      &-active {
        transition: 0.4s;
        max-height: 10000px;
        & .product__description__wrap_bg {
          bottom: 400%;
          transition: 1s;
        }
      }
      &_bg {
        transition: 1s;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0);
        background: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.68) 38%,
          rgba(255, 255, 255, 0.77) 43%,
          rgba(255, 255, 255, 1) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(255, 255, 255, 0)),
          color-stop(38%, rgba(255, 255, 255, 0.68)),
          color-stop(43%, rgba(255, 255, 255, 0.77)),
          color-stop(100%, rgba(255, 255, 255, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.68) 38%,
          rgba(255, 255, 255, 0.77) 43%,
          rgba(255, 255, 255, 1) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.68) 38%,
          rgba(255, 255, 255, 0.77) 43%,
          rgba(255, 255, 255, 1) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.68) 38%,
          rgba(255, 255, 255, 0.77) 43%,
          rgba(255, 255, 255, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.68) 38%,
          rgba(255, 255, 255, 0.77) 43%,
          rgba(255, 255, 255, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
      }
    }
    &__section {
      width: 44%;
      &_wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      & .product__group_description {
        margin: 30px 0;
      }
    }
    &_control {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      & .btn {
        width: 200px;
      }
    }
  }
}

.swiper-slide {
  height: auto;

  & img {
    width: 100%;
    height: 100%;
    min-height: 100px;
    object-fit: cover;

    @media (min-width: 768px) {
      min-height: 250px;
    }
  }
}
.gallery-thumbs {
  padding: 15px 0 0;

  & .swiper-wrapper {
    .swiper-slide {
      & img {
        height: 115px;
      }
    }

    & .swiper-slide-thumb-active {
      border: 1px solid #e04d27 !important;
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  background-image: none !important;
  height: 17px;
  & svg {
    height: 100%;
  }
}

.swiper-pagination {
  bottom: 0;
  & .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: solid 2px #d8d8d8;
    background-color: transparent;
    opacity: 1;
    &-active {
      background-color: #d8d8d8;
      opacity: 1;
    }
  }
}

.product {
  &_reviews {
    display: flex;
    justify-content: space-between;
    &-list {
      display: flex;
      flex-direction: column;
      width: 68%;
      max-height: 600px;
      overflow: auto;
    }
    &-form {
      width: 30%;
      border: 1px solid #dbdbdb;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
  }
}

.reviews {
  background: #fdfdfd;
  border: 1px solid #dbdbdb;
  padding: 20px 10px;
  margin-bottom: 10px;
  &_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
  }
  &__left-part {
    display: flex;
  }
  &-author {
    color: #1e1e1e;
    font-weight: 600;
  }
  &-date {
    font-size: 15px;
    color: grey;
    margin-left: 20px;
  }
  &-main {
    margin: 20px 0;
  }
}
.info__star_wrap {
  display: flex;
}
.info__star_item {
  width: 22px;
}
.tab {
  display: flex;
  flex-direction: column;
  &_header {
    margin: 20px 0;
    display: flex;
    align-items: center;
    &_item {
      padding: 15px 30px;
      margin-right: 10px;
      border: 1px solid #dbdbdb;
      position: relative;
      transition: 0.2s;
      &:hover {
        transition: 0.2s;
        cursor: pointer;
        background: rgb(224, 77, 39);
        color: #fff;
        & span {
          transition: 0.1s;
          background: transparent;
          color: #fff;
        }
      }

      & span {
        transition: 0.2s;
        height: 20px;
        width: 20px;
        background: transparent;
        position: absolute;
        border-radius: 50%;
        right: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #000;
        font-weight: 600;
      }
      &-active {
        transition: 0.2s;
        cursor: pointer;
        background: rgb(224, 77, 39);
        color: #fff;
        & span {
          transition: 0.1s;
          background: transparent;
          color: #fff;
        }
      }
    }
  }
  &_main {
    display: none;
    &-active {
      display: block;
    }
  }
}

.rating {
  display: flex;
  &__item {
    height: 17px;
    width: 17px;
    position: relative;
    & .star {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      z-index: 1;
      display: block;
      &_full {
        display: none;
        &-active {
          display: block;
          &-click {
            display: block;
          }
        }
      }
    }
  }
}
.right__right-part {
  & .info__star {
    &_wrap {
      display: flex;
      & .info__star_item {
        width: 22px;
        position: relative;
        & .star {
          position: absolute;
          display: block;
          height: 18px;
          width: 18px;
          &_full {
            display: none;
          }
        }
        &-active {
          & .star_full {
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
}
@media (max-width: 992px) {
  .product_reviews {
    flex-direction: column;
    &-list {
      width: 100%;
      margin-bottom: 20px;
    }
    &-form {
      width: 100%;
      max-width: 600px;
      min-width: 300px;

      margin: 0 auto;
    }
  }

  .product__main {
    flex-direction: column;
    &_section:last-child {
      width: 75%;
      margin-top: 20px;
    }
  }
}
@media (max-width: 768px) {
  .btn-order-buy {
    margin: 10px auto;
  }
  .group_box {
    & .select {
      min-width: 130px;
    }
  }

  .product__description__section_wrap {
    flex-direction: column;
    & .product__description__section {
      width: 100%;
    }
  }

  .product {
    &__description {
      &__wrap {
        flex-direction: column;
      }
      &__section {
        width: 90%;
        margin: auto;
      }
    }
    &__main {
      flex-direction: column;
      &_section {
        &:first-child {
          width: 75%;
          margin-top: 20px;
        }
        &_header {
          & .title {
            font-size: 26px;
            line-height: 1.6;
            color: #2f3134;
          }
          & .info {
            &__price {
              &-convert {
                & .info__price_valid {
                  font-size: 18px;
                }
              }
              &_valid {
                font-size: 20px;
              }
              &_invalid {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .product__group_description_item {
    flex-direction: column;
    & .product__group_description_name,
    & .product__group_description_value {
      width: 100%;
    }
  }
}

@media (max-width: 490px) {
  .product_wrap {
    & .product__main {
      flex-direction: column;
      &_section {
        width: 90%;
      }
    }
  }
  .product__control .btn {
    max-width: 245px;
    width: 47%;
  }
}

@media (max-width: 400px) {
  .info__star {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .product__control {
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    & .btn {
      max-width: 245px;
      width: 100%;
      margin: 10px;
    }
  }
}
