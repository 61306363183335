// main: ../style.sass

$main-color: #1599fc;
$secondary-color: #fff;
$greyy-color:#757575;

.btn{
    &-main_slider{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 140px;
      border: 1px solid #e04d27;
      transition: .3s;
      cursor: pointer;
        & svg{
          height: 60%;
          display: block;
          & path{
            fill: #e04d27;
          }
        }
      &:hover{
        transition: .3s;
        background: #e04d27;
        & svg{
          & path{
            fill: #fff;
          }
        }
      }
    }
    text-align: center;
    color: #dddddd;
    padding: 10px 15px;
    border: solid 1px #cecece;
    border-radius: 5px;
    cursor: pointer;
    &-login{
      margin: 0 10px;
    }
    &_slide{
      border-radius: 2px;
      background-color: #e04d27;
    }
    &_card{
      color: #e04d27;
      border-color: #e04d27;
      transition: .3s;
      &:hover{
        transition: .3s;
        background: #e04d27;
        color: #fff;
      }
    }
    &_form{

      border-radius: 2px;
      background-color: #e04d27;
      padding: 16px 59px;
      color: #fff;
      border: none;
      transition: .3s;
      border: solid 1px rgba(0,0,0,0);
      &:hover{
        transition: .3s;
        background: #fff;
        color: #e04d27;

        border: solid 1px #e04d27;
      }

    }
    &-product{
      border: solid 1px #e04d27;
      color: #e04d27;
      transition: .3s;
      cursor: pointer;
      border-radius: 2px;
      font-size: 14px;
      background: #fff;
      display: inline-block;
      &:hover{
        transition: .3s;
         background-color: #e04d27;
         color: #fff;
      }
    }
    &-disable{
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      color: #817979;
      cursor: default;
      &:hover{
        background-color: #f3f3f3;
        border-color: #f3f3f3;
        color: #817979;
        cursor: default;
      }
    }
    &-more{
      background: #fff;
      border: solid 1px #e04d27;
      color: #e04d27;
      transition: .3s;
      &:hover{
        background: #e04d27;
        border: solid 1px #e04d27;
        color: #fff;
        transition: .3s;
        justify-content: center;
      }
    }
    &_more{
      width: 200px;
      background: #e04d27;
      border: solid 1px #e04d27;
      color: #fff;
      transition: .3s;
      justify-content: center;
      &:hover{
        background: #fff;
        border: solid 1px #e04d27;
        color: #e04d27;
        transition: .3s;
      }

      display: flex;
      align-items: center;
      & .loader-anime {
        position: relative;
        visibility: hidden;
      }
      &-active{
        background-color: #e04d27;
        padding: 10px 52px 10px 15px;
         color: #fff;
      &  .loader-anime {
        position: relative;
        visibility: visible;
        transition-delay: .3s;
      }
      }
    }
    &-while{
      border-color: #fff;
      color: #fff;
        transition: .3s;
      &:hover{
        transition: .3s;
         background-color: #e04d27;
         color: #fff;
      }
    }

}
//
// .scroll_our-servise .arrow{
//   color: #fff;
//   transition: .1s;
//   &:hover{
//     transition: .1s;
//     color: rgba(21,153,252,1);
//   }
// }
//
// @media (max-width: 1450px){
//   .btn{
//     height: 68px;
//     width: 256px;
//
//     font-size: 18px;
//     &-round{
//       width: 68px;
//       height: 68px;
//     }
//   }
//
// }
//
//
//
// @media  (max-width: 1366px){
//   .btn{
//
//       height: 55px;
//       width: 235px;
//       font-size: 16px;
//         &-round{
//           width: 55px;
//           height: 55px;
//         }
//   }
//
// }
//
// @media  (max-width: 1200px){
//   .btn {
//
//     height: 50px;
//     width: 175px;
//     font-size: 14px;
//     &-round{
//       width: 50px;
//       height: 50px;
//     }
// }
//
// }
