// main: ../style.sass

.catalog {
  margin-bottom: 30px;
  &__product_control {
    display: flex;
    justify-content: center;
  }
  &__categories {
    &_title {
      text-align: center;
      font-size: 30px;
      color: #000000;
      margin: 30px 0;
    }
    &_box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &_item {
      width: 23%;
      transition: 0.3s;
      margin: 0 1%;
      &:last-child {
        margin: 0 0 0 1%;
      }
      &:first-child {
        margin: 0 1% 0 0;
      }
      &-img {
        width: 100%;
        & img {
          width: 100%;
          height: auto;
        }
      }
      &-title {
        color: #000000;
        text-align: center;
        margin: 10px 0;
      }
      &:hover {
        transform: scale(1.1);
        transition: 0.3s;
        & .catalog__categories_item-title {
          color: #e04d27;
        }
      }
    }
  }
  &__all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    & .card {
      width: 23%;
      min-width: 270px;
      margin-bottom: 30px;
    }
  }
}

.tile {
  animation: 1s ease 0s pulse infinite;
  background: $tile-bg;
  border-radius: 4px;
  float: left;
  min-height: 275px;
  margin: 5px;
  width: 100px;
  transform: scale(1) !important;
  display: none;
  &-active {
    display: block;
  }
  &:hover {
    transform: scale(1) !important;
  }
}

// Loop from 1-9.

// .tile{
//   &:nth-child(odd){
//     background: green;
//   }
//   &:nth-child(even){
//     background: red;
//   }
//   // &:nth-child(4n){
//   //   background: blue;
//   // }
// }
// Loop from 1-12.
@for $i from 1 through 24 {
  .block:nth-child(#{$i}) {
    animation-delay: $i * (0.03s);
  }
}
@media (max-width: 1220px) {
  .catalog__all {
    & .card {
      width: 27%;
      min-width: 270px;
      // margin-bottom: 30px;
      margin: 10px 10px 30px;
    }
  }
}
@media (max-width: 992px) {
  .catalog__all {
    & .card {
      width: 38%;
    }
  }
}
@media (max-width: 920px) {
  .catalog__categories_box {
    flex-wrap: wrap;
    justify-content: space-around;

    & .catalog__categories_item {
      width: 35%;
    }
  }
}
@media (max-width: 768px) {
  .catalog__all {
    & .card {
      width: 65%;
    }
  }
}
@media (max-width: 678px) {
  .catalog__categories_box {
    & .catalog__categories_item {
      width: 55%;
      min-width: 270px;
    }
  }
}
@media (max-width: 400px) {
  .catalog__categories_title {
    font-size: 25px;
  }
}
