// main: ../style.sass

.contact {
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin: 40px 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_title {
      font-size: 30px;
      line-height: 2.37;
      color: #000000;
    }
    &_text {
      font-family: NotoSans;
      font-size: 16px;
      line-height: 1.63;
      text-align: justify;
      color: #95999d;
    }
  }
  &__social {
    flex-direction: column;
    display: flex;
    &_item {
      display: inline-flex;
      align-items: center;
      margin: 7px 0;
      &-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        & svg {
          width: 80%;
          height: auto;
        }
      }
      &-name {
        font-size: 16px;
        color: #95999d;
      }
    }
  }

  &__form_wrap {
    margin-top: -50px;
  }

  &__map {
    display: block;
    height: 100%;
  }
}

@media (max-width: 992px) {
  .contact {
    &__social_wrap {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &__social {
      margin-top: -20px;
    }

    &__form {
      &_wrap {
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: -10px;
      }
    }
    &__wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 20px;
      margin: 40px 0;
    }
    &__map {
      display: block;
      height: 100%;
      height: 50vw;
      min-height: 300px;
      &_wrap {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
@media (max-width: 992px) {
  .contact {
    &__social_wrap {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__form {
      &_wrap {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
    &__wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 20px;
      margin: 40px 0;
      grid-column-gap: 0px;
    }
    &__map {
      display: block;
      height: 100%;
      height: 50vw;
      min-height: 300px;
      &_wrap {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
