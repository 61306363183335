// main: ../style.sass

.basket {
  display: none;
  margin: 10px 0 60px;
  min-height: 400px;
  align-items: center;
  &_empty {
    padding: 40px 0;
  }
  &_wrap {
    min-height: 150px;
    margin: 30px auto;
    display: none;
    &-active {
      display: block;
    }
  }
  &-active {
    display: block;
  }
  &__title {
    font-size: 30px;
    line-height: 2.37;
    letter-spacing: -0.33px;
    color: #000000;
    text-align: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  &__step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    position: relative;

    & .step {
      width: 25px;
      height: 25px;
      border: solid 1px #e5e5e5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      z-index: 1;
      background: #fff;
      cursor: default;
      cursor: pointer;
      &_bg {
        position: absolute;
        height: 1px;
        background-color: #e5e5e5;
        width: 100%;
        z-index: 0;
      }
      &-number {
      }
      &-chesk {
        display: none;
      }
      &-action {
        border: 1px solid #e04d27;
        background-color: #e04d27;
        color: #fff;
        font-weight: 600;
        & .step-number {
          display: block;
        }
      }
    }
  }

  &__group {
    &_col-1 {
      width: 70%;
      display: flex;

      .basket_item_img_link {
        width: 20%;
      }
    }
    &_col-2 {
      width: 30%;
      display: flex;
      &_mod {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
    &_title {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #e5e5e5;
      & .item_heder {
        width: 75%;
      }
      & .count_heder {
        width: 40%;
        text-align: center;
      }
      & .price_heder {
        width: 40%;
        text-align: center;
      }
      & .delete_product {
        width: 75px;
      }
      & div {
        font-size: 12px;
        letter-spacing: 1px;
        color: #8d8d8d;
        text-transform: uppercase;
      }
      & .f-2 {
        display: flex;
        width: 30%;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__main {
    position: relative;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_group {
      display: flex;
      align-items: center;
    }
    &_img {
      width: 100%;
      & img {
        width: 100%;
        height: auto;
        max-width: 16 0px;
      }
    }
    &_title {
      display: block;
      width: 80%;
      & div {
        font-size: 20px;
        color: #000000;
        margin: 20px;
      }

      &:hover {
        div {
          transition: 0.3s;
          color: #e04d27;
        }
      }
    }
    &_quantity {
      width: 40%;
      text-align: center;
      & input {
        border: solid 1px #e5e5e5;
        width: 60px;
      }
    }
    &_price {
      width: 40%;
      text-align: center;
    }
    &_delete {
      width: 75px;
      text-align: center;
      &_ico {
        cursor: pointer;
        &:hover {
          & svg path {
            transition: 0.3s;
            fill: #e04d27;
          }
        }
      }
      & svg {
        height: 20px;
        & path {
          transition: 0.3s;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    &_price {
      margin: 27px 0;
      display: flex;
      &-title {
        font-size: 12px;
        line-height: 1.83;
        letter-spacing: 1px;
        color: #9b9b9b;
        text-transform: uppercase;
        margin: 0 10px;
      }
      &-value {
        font-size: 18px;
        line-height: 1.22;
        letter-spacing: 1.5px;
        color: #000000;
      }
    }
  }
  &__control {
    display: flex;
    margin: 20px 0;
    justify-content: flex-end;
    & .btn {
      width: 243px;
      border-radius: 2px;
    }
  }
}
.step-done {
  & .step-number {
    display: none !important;
  }
  & .step-chesk {
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      height: 100%;
    }
  }
}
.contact_user {
  display: none;
  &-active {
    display: block;
    margin: 30px 0;
  }
  &__wrap {
    width: 50%;
    margin: 0 auto;
  }
  &__control {
    display: flex;
    justify-content: flex-end;
    & .btn {
      display: inline-block;
      width: 240px;
    }
  }
}
.group {
  &_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 22px 0;
  }
  &_box {
    width: 48%;
    display: flex;
    flex-direction: column;
    & label {
      font-size: 14px;
      font-weight: 500;
      color: #757575;
      margin-bottom: 8px;
    }
    & input {
      padding: 7px 5px;
      border: solid 1px #e5e5e5;

      font-size: 16px;
      color: #000000;
    }
  }
}

@media (max-width: 1366px) {
  .contact_user__wrap {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .contact_user__wrap {
    width: 550px;
  }
}
@media (max-width: 768px) {
  .basket__group_col-1 {
    .basket_item_img_link {
      min-width: 120px;
    }
  }

  .basket__group_title {
    & .count_heder {
      display: none;
    }
    & .price_heder {
      display: none;
    }
  }
  .basket__item {
    flex-direction: column;
  }
  .basket__group_col-1 {
    width: 90%;
    align-items: center;
    justify-content: space-between;
  }
  .basket__group_col-2 {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    &_mod {
      width: 50%;
      min-width: 250px;
    }
  }
  .basket__item_title {
    display: flex;
    justify-content: flex-end;
  }
  .basket__item {
    flex-direction: column;
    margin: 20px 0 30px;
  }
}
@media (max-width: 640px) {
  .contact_user__wrap {
    width: 90%;
  }
}
@media (max-width: 520px) {
  .basket__step {
    width: 75%;
  }
  .group_row {
    flex-direction: column;
    margin: 0px;
  }
  .group_box {
    max-width: 300px;
    width: 90%;
    margin: 6px 0;
  }
  .contact_user__control {
    margin: 25px;
  }
  .basket__group_col-1 {
    width: 100%;
  }
  .basket__item_title div {
    font-size: 16px;
    margin: 10px;
  }
}
