/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
* {
  font-family: "Noto Sans", sans-serif;
}

html {
  font-family: "Noto Sans", sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

textarea {
  max-width: 100%;
  min-width: 100%;
  border: solid 1px #cccccc;
  background-color: #fefefe;
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  font-family: "Noto Sans", sans-serif;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a {
  transition: 0.3s;
}
a:active, a:hover {
  outline: 0;
  transition: 0.3s;
  color: #e04d27;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: "Noto Sans", sans-serif;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  box-sizing: content-box; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.btn {
  text-align: center;
  color: #dddddd;
  padding: 10px 15px;
  border: solid 1px #cecece;
  border-radius: 5px;
  cursor: pointer;
}
.btn-main_slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 140px;
  border: 1px solid #e04d27;
  transition: 0.3s;
  cursor: pointer;
}
.btn-main_slider svg {
  height: 60%;
  display: block;
}
.btn-main_slider svg path {
  fill: #e04d27;
}
.btn-main_slider:hover {
  transition: 0.3s;
  background: #e04d27;
}
.btn-main_slider:hover svg path {
  fill: #fff;
}
.btn-login {
  margin: 0 10px;
}
.btn_slide {
  border-radius: 2px;
  background-color: #e04d27;
}
.btn_card {
  color: #e04d27;
  border-color: #e04d27;
  transition: 0.3s;
}
.btn_card:hover {
  transition: 0.3s;
  background: #e04d27;
  color: #fff;
}
.btn_form {
  border-radius: 2px;
  background-color: #e04d27;
  padding: 16px 59px;
  color: #fff;
  border: none;
  transition: 0.3s;
  border: solid 1px rgba(0, 0, 0, 0);
}
.btn_form:hover {
  transition: 0.3s;
  background: #fff;
  color: #e04d27;
  border: solid 1px #e04d27;
}
.btn-product {
  border: solid 1px #e04d27;
  color: #e04d27;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
  background: #fff;
  display: inline-block;
}
.btn-product:hover {
  transition: 0.3s;
  background-color: #e04d27;
  color: #fff;
}
.btn-disable {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
  color: #817979;
  cursor: default;
}
.btn-disable:hover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
  color: #817979;
  cursor: default;
}
.btn-more {
  background: #fff;
  border: solid 1px #e04d27;
  color: #e04d27;
  transition: 0.3s;
}
.btn-more:hover {
  background: #e04d27;
  border: solid 1px #e04d27;
  color: #fff;
  transition: 0.3s;
  justify-content: center;
}
.btn_more {
  width: 200px;
  background: #e04d27;
  border: solid 1px #e04d27;
  color: #fff;
  transition: 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
}
.btn_more:hover {
  background: #fff;
  border: solid 1px #e04d27;
  color: #e04d27;
  transition: 0.3s;
}
.btn_more .loader-anime {
  position: relative;
  visibility: hidden;
}
.btn_more-active {
  background-color: #e04d27;
  padding: 10px 52px 10px 15px;
  color: #fff;
}
.btn_more-active .loader-anime {
  position: relative;
  visibility: visible;
  transition-delay: 0.3s;
}
.btn-while {
  border-color: #fff;
  color: #fff;
  transition: 0.3s;
}
.btn-while:hover {
  transition: 0.3s;
  background-color: #e04d27;
  color: #fff;
}

.tile:nth-child(odd) {
  animation-delay: 0.1s;
}
.tile:nth-child(even) {
  animation-delay: 0.2s;
}

@keyframes pulse {
  0% {
    background: #cfcfdf;
    transform: scale(1);
  }
  25% {
    background: #b0b0cb;
    transform: scale(1.015);
  }
  50% {
    background: #cfcfdf;
    transform: scale(1);
  }
}
.block {
  animation: 0.3s ease-in-out both fade-in;
  border-radius: 4px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.loader_all {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99993;
  top: 0;
  left: 0;
}
.loader_all-active {
  display: block;
}
.loader_all__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

* {
  box-sizing: border-box;
}

.body_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.wrapper {
  width: 1380px;
  margin: 0 auto;
}

.section {
  margin-bottom: 50px;
}
.section__container {
  margin: 60px 0;
}
.section:last-child {
  margin-bottom: 0;
}
.section_title {
  font-size: 28px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 30px;
}
.section_title-center {
  text-align: center;
}

.delivery__row {
  display: flex;
  justify-content: space-between;
  min-height: 400px;
  align-items: center;
}
.delivery__box {
  margin-bottom: 60px;
}
.delivery__section {
  width: 48%;
}
.delivery__section_li {
  display: flex;
}
.delivery__section_icon {
  margin: 5px 10px 5px 0;
}
.delivery__section_title {
  font-size: 30px;
  line-height: 2.37;
}
.delivery__section_subtitle {
  font-size: 22px;
  font-weight: 600;
  margin: 15px 0;
}
.delivery__section_subtitle-header {
  margin-top: 60px;
}
.delivery__section_text {
  font-size: 16px;
  color: #95999d;
  line-height: 1.63;
}
.delivery__section_text strong {
  font-weight: 900;
  color: #000000;
}
.delivery__section_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delivery__section_partment {
  border: solid 1px #e5e5e5;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}
.delivery__section_partment_box {
  display: flex;
  align-items: baseline;
}
.delivery__section_partment_namber {
  font-size: 30px;
  color: #000000;
  line-height: 1;
}
.delivery__section_partment_text {
  font-size: 16px;
  color: #95999d;
  margin: 0 10px;
}
.delivery__section_main p {
  color: #95999d;
  font-size: 16px;
  line-height: 1.7;
}
.delivery__section_main p .select_color {
  color: #e04d27;
}
.delivery__pay {
  width: 80%;
  background: #f3f3f3;
  padding: 33px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.delivery__pay_img {
  margin-bottom: 25px;
  height: 30px;
}
.delivery__pay_img img {
  height: 100%;
  width: auto;
}
.delivery__pay_text {
  font-size: 20px;
  text-align: center;
}

@media (max-width: 1250px) {
  .delivery__pay {
    width: 100%;
  }
  .delivery__section_partment_namber {
    font-size: 24px;
  }
  .delivery__section_partment_img {
    width: 100px;
  }
  .delivery__section_partment_img svg {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1150px) {
  .delivery__row_colum {
    flex-direction: column;
  }
  .delivery__row_colum .delivery__section {
    width: 100%;
  }
  .delivery__row_colum .delivery__section .delivery__section_partment {
    max-width: 768px;
    margin: 30px auto;
  }
  .delivery__row_colum .delivery__section_img {
    width: 35%;
  }
  .delivery__row_colum .delivery__section_txt {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .delivery__section_partment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .delivery__section_partment_img {
    grid-column-start: 1;
    grid-column-end: 3;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    height: 45px;
    margin-bottom: 20px;
  }
  .delivery__section_partment_img svg {
    height: 100%;
  }
  .delivery__section_partment_box {
    justify-content: center;
  }
  .delivery__section_subtitle-header {
    margin-top: 10px;
  }
  .delivery__section_img {
    margin: 50px 0;
  }
  .delivery__row {
    min-height: 100px;
    flex-direction: column;
  }
  .delivery__row .delivery__section {
    width: 100%;
  }
  .delivery__colum_reservs {
    flex-direction: column-reverse;
  }
}
@media (max-width: 390px) {
  .delivery {
    width: 92% !important;
  }
  .delivery__section_img {
    max-width: 88%;
  }
  .delivery__section_partment {
    grid-template-columns: 1fr;
    max-width: 80% !important;
  }
  .delivery__section_partment_box {
    margin: 10px 0;
  }
  .delivery__section_partment_img {
    grid-column-end: 2;
  }
}
@media (max-width: 1400px) {
  .wrapper {
    width: 100%;
    padding: 0 4%;
  }
}
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fbfbfb;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #fbfbfb;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 2px;
}
.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 20px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.7142857143px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.form__row {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.form__row:first-child {
  margin-top: 0;
}
.form__row label {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  margin-bottom: 8px;
}
.form__row label span {
  color: #e04d27;
}
.form__row input {
  font-size: 18px;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #fefefe;
}
.form__row textarea {
  width: 100%;
}

.form__agree_terms {
  display: flex;
  align-items: center;
  flex-direction: row !important;
}
.form__agree_terms [type=checkbox] {
  width: 18px;
  height: 18px;
  margin-right: 10px !important;
}

.hidden {
  display: none;
}

.thank_you {
  padding: 0 30px;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}
.thank_you__wrap {
  text-align: center;
  padding: 160px 0;
}
.thank_you__wrap .btn {
  margin: 30px 0;
}

.link {
  transition: 0.3s;
}
.link:hover {
  cursor: pointer;
  color: #e04d27;
  transition: 0.3s;
}
.link:hover svg path {
  fill: #e04d27;
  transition: 0.3s;
}

.irs--flat .irs-bar {
  background: transparent;
}
.irs--flat .irs-line {
  height: 9px;
}
.irs--flat .irs-handle {
  top: 21px;
  border-radius: 2px;
  width: 15px;
  height: 19px;
  background-color: #878786;
}
.irs--flat .irs-handle i {
  display: none !important;
}

label.error {
  font-size: 12px !important;
  color: red !important;
}

.loader {
  position: absolute;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  left: 25px;
}

@-webkit-keyframes load5 {
  0%, 100% {
    box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5), 2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5), 0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5), -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
  }
}
@keyframes load5 {
  0%, 100% {
    box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff, 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff, 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5), 2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff, 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff, -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2), -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5), 0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff, -2.6em 0 0 0 rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff, -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2), 2.5em 0 0 0 rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2), 0 2.5em 0 0 rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5), -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
  }
}
.select {
  position: relative;
  border: solid 1px #e5e5e5;
  color: #000000;
  min-width: 260px;
}
.select__intup {
  background: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  min-height: 38px;
}
.select .field_text {
  color: inherit;
  width: 90%;
}
.select__wrap {
  font-size: 14px;
  color: #000;
  padding: 10px;
  position: absolute;
  border: solid 1px #e5e5e5;
  width: 100%;
  top: 45px;
  z-index: 15;
  background: #fff;
  overflow-y: auto;
  transition: 0.5s;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}
.select__wrap-active {
  max-height: 150px;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
}
.select__wrap_item {
  padding: 5px 10px;
  font-weight: 500;
}
.select__wrap_item:first-child {
  padding-top: 0px;
}
.select__wrap_item:last-child {
  padding-bottom: 0px;
}
.select__wrap_item:hover {
  color: #1599fc;
  cursor: pointer;
}
.select__wrap-disabled {
  color: #8f9592 !important;
  cursor: default !important;
}

.field {
  display: flex;
  align-items: center;
}
.field:hover {
  cursor: pointer;
}
.field__icon,
.field .field__icon_click {
  height: 100%;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.field_box {
  height: 26px;
  display: block;
  margin: 0 auto;
}
.field_wrap {
  padding-top: 12px;
  transform: rotateX(180deg);
  height: 40px;
  display: block;
  margin: 0 auto;
}

.field__icon_click {
  z-index: 10;
}

.field .field__icon_wrap {
  transform: rotateX(0deg);
  width: 30%;
  height: 100%;
  transition: 0.3s;
}

.field-active .field__icon_wrap {
  transform: rotateX(-180deg);
  width: 30%;
  height: 100%;
  transition: 0.3s;
}

.form_send_user_ok {
  display: inline-block;
  max-width: 400px;
  padding: 20px;
}
.form_send_user_ok .fa-check-circle {
  margin: 20px 0;
  height: 70px;
  width: 100%;
  text-align: center;
}
.form_send_user_ok-title {
  text-align: center;
}

@media (max-width: 1400px) {
  .thank_you__wrap {
    padding: 110px 0;
  }
}
@media (max-width: 992px) {
  .thank_you {
    padding: 0 10px;
  }
  .thank_you__wrap {
    padding: 90px 0;
  }
}
.body_bg {
  position: absolute;
  width: 100%;
  height: 1000%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.8);
  display: none;
}
.body_bg-active {
  display: block;
}

.body__navbar-active {
  overflow: hidden;
}

.header {
  height: 100px;
  background: #2a3860;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__section {
  display: flex;
  flex-direction: row;
  width: 45%;
  align-items: center;
  justify-content: space-between;
}
.header__section_phone {
  display: flex;
  flex-direction: column;
}
.header__section_login {
  position: relative;
}
.header__section_login-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__section_login-img {
  height: 40px;
  width: 40px;
}
.header__section_login-img img {
  height: 100%;
  width: auto;
  border-radius: 50%;
}
.header__section_login-control {
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
  transition: 0.3s;
}
.header__section_login-control:hover svg path {
  transition: 0.3s;
  fill: #e04d27;
}
.header__section_login-control svg {
  height: 100%;
  width: auto;
}
.header__section_login-control svg path {
  transition: 0.3s;
  fill: #fff;
}
.header__section_login-control-active {
  transform: rotate(-180deg);
  transition: 0.3s;
}
.header__section_logout {
  display: flex;
}
.header__section_search {
  margin-left: 30px;
  border-radius: 5px;
}
.header__section_search form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #ffffff;
  position: relative;
  margin: 0;
}
.header__section_search form input, .header__section_search form input:active, .header__section_search form input:focus {
  border: none;
  font-size: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  background-color: #ffffff;
  position: relative;
  padding: 10px 20px;
  margin-right: 40px;
  margin-left: 5px;
}
.header__section:nth-child(1) {
  width: 80%;
}
.header__section:nth-child(2) {
  justify-content: flex-end;
  width: 40%;
}
.header__section_hamburger {
  display: none;
}

.section__login_info {
  position: absolute;
  background: #2a3860;
  z-index: 1;
  padding: 0 15px;
  left: -55px;
  width: 216px;
  color: #fff;
  font-size: 14px;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  transition: 0.3s;
}
.section__login_info-active {
  height: 60px;
  transition: 0.3s;
}
.section__login_info-footer {
  border-top: 1px solid #fff;
  margin-top: 10px;
  padding: 5px 0;
}

.search__form-focus .search__icon_wrap .next, .search__form-focus .search__icon_wrap .blog_search-next, .search__form-blog .search__icon_wrap .next, .search__form-blog .search__icon_wrap .blog_search-next {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 0.7s;
}
.search__form-focus .search__icon_wrap .search, .search__form-blog .search__icon_wrap .search {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transition: 0.7s;
}
.search__form-focus .search__icon_wrap:hover, .search__form-blog .search__icon_wrap:hover {
  cursor: pointer;
}
.search__form-focus .search__icon_wrap:hover .next svg, .search__form-blog .search__icon_wrap:hover .next svg {
  transition: 0.3s;
  transform: translateX(4px);
}
.search__form-focus .search__icon_wrap:hover .next svg path, .search__form-blog .search__icon_wrap:hover .next svg path {
  transition: 0.3s;
  fill: #e04d27;
}
.search__form-focus .search__icon_wrap .next svg path, .search__form-blog .search__icon_wrap .next svg path {
  transition: 0.3s;
}
.search__form-focus .search__icon_wrap .next .search, .search__form-blog .search__icon_wrap .next .search {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transition: 0.7s;
}

.search__icon_wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: 35px;
  width: 35px;
  overflow: hidden;
  transition: 0.3s;
}
.search__icon_wrap .next {
  transform: translateX(-200%);
  transition: 0.7s;
}
.search__icon_wrap .blog_search-next {
  transform: translateX(-200%);
  transition: 0.7s;
}
.search__icon_wrap .search {
  transform: translateX(0%);
  transition: 0.7s;
}
.search__icon_wrap-focus, .search__icon_wrap-blog {
  cursor: pointer;
}
.search__icon_wrap-focus .blog_search-next, .search__icon_wrap-blog .blog_search-next {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 0.7s;
}
.search__icon_wrap-focus .next, .search__icon_wrap-blog .next {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: 0.7s;
}
.search__icon_wrap-focus .search, .search__icon_wrap-blog .search {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transition: 0.7s;
}
.search__icon_lev {
  position: absolute;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search__icon_lev:hover .next svg {
  transition: 0.3s;
  transform: translateX(4px);
}
.search__icon_lev svg {
  height: 60%;
  width: auto;
}

.basket__box_icon {
  display: block;
  position: relative;
  margin: 0 20px;
}
.basket__box_icon svg path {
  transition: 0.3s;
}
.basket__box_icon:hover svg path {
  transition: 0.3s;
  fill: #e04d27;
  cursor: pointer;
}
.basket__icon {
  position: relative;
  display: block;
}
.basket__count {
  transform: translate(50%, -50%);
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  width: 22px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basket__count span {
  color: #191a1d;
  font-size: 13px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .header__section_search {
    max-width: 210px;
  }
}
@media (max-width: 992px) {
  .header__section_search {
    max-width: 400px;
  }
  .header__section_hamburger {
    display: block;
  }
  .header__section_auth-wrap-header {
    display: none;
  }
  .basket__box_icon {
    margin: 0 30px;
  }
  .navbar__wrap-active .section__login_info {
    background: #3e4559;
    left: -55px;
    width: 215px;
    border-radius: 5px;
  }
}
@media (max-width: 640px) {
  .header__section:nth-child(2) {
    width: 30%;
    justify-content: space-around;
  }
  .header__section_service .header__section_auth {
    display: none;
  }
  .header__section_service .header__section_auth-wrap {
    margin: 0 10px;
  }
}
@media (max-width: 572px) {
  .header__section_service .header__section_auth {
    display: none;
  }
}
@media (max-width: 530px) {
  .header__section_logo {
    max-width: 125px;
  }
  .header__section_logo img {
    width: 100%;
  }
}
@media (max-width: 440px) {
  .header__section_logo {
    max-width: 125px;
  }
  .header__section_logo img {
    width: 100%;
  }
  .header__section_phone_item {
    font-size: 14px;
  }
}
@media (max-width: 410px) {
  .header__section:nth-child(1) {
    width: 55%;
    justify-content: space-around;
  }
  .header__section:nth-child(2) {
    width: 40%;
    justify-content: space-around;
  }
  .header__section_logo {
    max-width: 125px;
  }
  .header__section_logo img {
    width: 100%;
  }
  .header__section_phone {
    display: none;
  }
}
.footer__info .footer__info_logo {
  display: none;
}

.footer {
  background: #2a3860;
}
.footer .section {
  margin-bottom: 0px;
}
.footer__info {
  padding: 26px 0;
  display: flex;
  justify-content: space-around;
}
.footer__info_text {
  color: #fff;
}
.footer__wrap {
  padding: 80px 0 30px;
  display: grid;
  grid-template-columns: 22% 19% 11% 10% 10% 90px;
  justify-content: space-between;
}
.footer__group_header {
  min-height: 60px;
  display: flex;
  align-items: center;
  color: #fff;
}
.footer__group_main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer__group_row, .footer__group_row a {
  font-size: 16px;
  line-height: normal;
  color: #d4d4d4;
}
.footer__group_coll {
  flex-direction: column;
  display: flex;
}
.footer__group_row-social {
  display: flex;
  justify-content: space-between;
  max-width: 90px;
}
.footer__group_info {
  display: flex;
  justify-content: space-between;
  height: 29px;
}
.footer__group_info-playment {
  display: grid;
  height: 29px;
  grid-template-columns: 0.7fr 1fr;
  grid-template-rows: 29px;
}
.footer__group_info .footer__info_logo {
  display: none;
}
.footer__group_info-item img {
  height: 100%;
  width: auto;
}

.section__footer {
  border-top: 1px solid #fff;
  background: #2a3860;
}

@media (max-width: 1366px) {
  .footer {
    background: #2a3860;
  }
  .footer__info {
    padding: 26px 0;
    display: flex;
    justify-content: space-around;
  }
  .footer__info_text {
    color: #fff;
  }
  .footer__wrap {
    grid-template-columns: 22% 19% 13% 10% 10% 90px;
  }
}
@media (max-width: 1100px) {
  .footer__wrap {
    grid-template-columns: 35% 30% 15%;
    grid-row-gap: 30px;
  }
  .footer__info_logo {
    max-width: 100px;
  }
  .footer__group_info {
    max-width: 140px;
  }
  .footer__group_4 {
    grid-column-start: 3;
    grid-row-start: 1;
  }
}
@media (max-width: 768px) {
  .footer__wrap {
    padding: 30px 0 30px;
    align-items: center;
    grid-template-columns: 35% 110px 15%;
  }
  .footer__group_2, .footer__group_3, .footer__group_4 {
    display: none;
  }
  .footer__group_1 .footer__group_main {
    display: none;
  }
  .footer__group_6 .footer__group_header {
    display: none;
  }
}
@media (max-width: 500px) {
  .footer__wrap {
    display: none;
  }
  .footer__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer__info_logo {
    display: block !important;
    margin-top: 30px;
    width: 100px;
  }
}
.navbar {
  font-family: "Noto Sans", sans-serif;
  background-color: #191a1d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .navbar__service_header {
  padding: 0 4%;
}
.navbar .navbar__service_header .header__section_search {
  margin: 0;
}
.navbar__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar__wrap_header {
  display: none;
}
.navbar__main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar_item {
  margin: 0 40px 0 0;
  color: #fff;
  transition: 0.3s;
  position: relative;
}
.navbar_item:last-child {
  margin: 0;
}
.navbar_item .navbar_item_text {
  padding: 10px 0;
}
.navbar_item .navbar_item_text svg {
  display: none;
}
.navbar_item:hover {
  cursor: pointer;
  color: #e04d27;
  transition: 0.3s;
}
.navbar__submenu {
  cursor: default;
  position: absolute;
  background: #fff;
  padding: 29px;
  min-width: 370px;
  top: 55px;
  -webkit-box-shadow: 0 0 23px -6px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 23px -6px rgba(0, 0, 0, 0.58);
  box-shadow: 0 0 23px -6px rgba(0, 0, 0, 0.58);
  visibility: hidden;
  opacity: 0;
  z-index: -5;
  transition: 0.3s;
}
.navbar__submenu-active {
  visibility: visible;
  opacity: 1;
  z-index: 5;
  transform: translateY(-15px);
  transition: 0.3s;
}
.navbar__submenu_prev-step {
  display: none;
}
.navbar__submenu_header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #8bc34a;
  align-items: center;
}
.navbar__submenu_header .title a {
  font-family: "Noto Sans", sans-serif;
  color: #000000;
}
.navbar__submenu_header .all_count {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: #878786;
}
.navbar__submenu_main {
  padding-top: 20px;
}
.navbar__submenu_item {
  color: #878786;
  font-size: 15px;
  padding: 5px 0;
  transition: 0.3s;
  cursor: pointer;
}
.navbar__submenu_item:hover {
  cursor: pointer;
  color: #e04d27;
  transition: 0.3s;
}
.navbar__service_header, .navbar__service_footer {
  display: none;
}

@media (max-width: 1366px) {
  .navbar_item {
    margin: 0 20px 0 0;
  }
}
@media (max-width: 1100px) {
  .navbar_item {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .navbar {
    width: 350px;
    position: fixed;
    align-items: flex-start;
    min-height: 100%;
    height: 100%;
    top: 0;
    right: 0;
    overflow: hidden;
    background: inherit;
    z-index: -5;
  }
  .navbar__service_footer {
    width: 100%;
    margin: 30px 0px;
  }
  .navbar__service_header, .navbar__service_footer {
    display: block;
  }
  .navbar-active {
    z-index: 5;
  }
  .navbar .wrapper {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .navbar__wrap {
    background: #191a1d;
    flex-direction: column;
    right: 0;
    top: 0;
    height: 100%;
    justify-content: space-around;
    transition: 0.3s;
    align-items: flex-start;
    transform: translateX(200%);
  }
  .navbar__wrap-active {
    transform: translateX(0%);
  }
  .navbar__wrap_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .navbar__wrap_header-logo {
    height: 30px;
  }
  .navbar__wrap_header-logo_link {
    display: block;
    height: 100%;
  }
  .navbar__wrap_header-logo_link img {
    height: 100%;
  }
  .navbar_item {
    width: 100%;
    margin: 10px 0;
    padding: 0 6%;
    position: static;
  }
  .navbar_item .navbar_item_text {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  .navbar_item .navbar_item_text svg {
    display: block;
    height: 18px;
  }
  .navbar__main {
    width: 100%;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    align-items: flex-start;
    display: flex;
    position: relative;
    transition: 0.3s;
  }
  .navbar__main_next-step {
    transition: 0.3s;
    transform: translateX(-100%);
  }
  .navbar__submenu {
    width: 100%;
    top: 0;
    left: 100%;
    padding: 10px;
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    min-width: 270px;
  }
  .navbar__submenu_header {
    padding-bottom: 5px;
  }
  .navbar__submenu_header .title a {
    color: #fff;
  }
  .navbar__submenu_header .all_count {
    display: none;
  }
  .navbar__submenu_item {
    color: #fff;
    margin: 15px 0;
  }
  .navbar__submenu-active {
    transform: translateY(0);
  }
  .navbar__submenu-active_mob {
    z-index: 13;
    visibility: visible;
    opacity: 1;
  }
  .navbar__submenu_prev-step {
    display: block;
    height: 100%;
    width: 50px;
    background: rgba(255, 255, 255, 0.4);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar__submenu_prev-step:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
  }
  .navbar__submenu_prev-step svg {
    height: 20px;
  }
  .navbar__submenu_main {
    padding-top: 0;
  }
  .header__section_logout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    padding-inline: 10px;
    gap: 10px;
  }
  .header__section_logout .btn {
    width: 90%;
  }
  .header__section .header__section_search {
    display: none;
  }
}
.hamburger {
  padding: 15px 0;
}

@media (max-width: 450px) {
  .navbar {
    width: 100%;
  }
}
.main_slider {
  outline: none;
}
.main_slider_control {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
}
.main_slider_box {
  outline: none;
}
.main_slider_title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  color: #2f3134;
}
.main_slider_main {
  font-size: 16px;
  line-height: 1.63;
  text-align: justify;
  color: #95999d;
  margin: 40px 0 20px;
}
.main_slider_btn {
  width: 195px;
}
.main_slider_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_slider_section:nth-child(1) {
  width: 35%;
}
.main_slider_section:nth-child(2) {
  width: 65%;
}
.main_slider_animation {
  width: 100%;
}
.main_slider_animation video {
  width: 100%;
}

@media (max-width: 1366px) {
  .main_slider_title {
    font-size: 36px;
  }
  .main_slider_section:nth-child(1) {
    width: 40%;
  }
  .main_slider_section:nth-child(2) {
    width: 60%;
  }
}
@media (max-width: 1100px) {
  .main_slider_title {
    font-size: 32px;
  }
}
@media (max-width: 992px) {
  .main_slider_title {
    font-size: 45px;
  }
  .main_slider .slick-track {
    display: flex;
  }
  .main_slider_box {
    height: auto;
  }
  .main_slider_item {
    flex-direction: column-reverse;
    height: 100%;
  }
  .main_slider_section:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 100%;
  }
  .main_slider_section:nth-child(2) {
    width: 70%;
  }
  .main_slider_btn {
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .main_slider_section:nth-child(1) {
    width: 90%;
  }
  .main_slider_section:nth-child(2) {
    width: 90%;
  }
  .main_slider_main {
    margin: 20px 0 15px;
  }
  .main_slider_btn {
    margin: 0 auto;
  }
}
@media (max-width: 600px) {
  .main_slider_control {
    margin: 20px 0;
  }
  .main_slider_title {
    font-size: 33px;
  }
}
@media (max-width: 440px) {
  .main_slider_title {
    font-size: 29px;
  }
}
@media (max-width: 380px) {
  .main_slider_title {
    font-size: 25px;
  }
}
.slick-track {
  outline: none;
}
.slick-track:focus {
  outline: none;
}

.catalog {
  margin-bottom: 30px;
}
.catalog__product_control {
  display: flex;
  justify-content: center;
}
.catalog__categories_title {
  text-align: center;
  font-size: 30px;
  color: #000000;
  margin: 30px 0;
}
.catalog__categories_box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.catalog__categories_item {
  width: 23%;
  transition: 0.3s;
  margin: 0 1%;
}
.catalog__categories_item:last-child {
  margin: 0 0 0 1%;
}
.catalog__categories_item:first-child {
  margin: 0 1% 0 0;
}
.catalog__categories_item-img {
  width: 100%;
}
.catalog__categories_item-img img {
  width: 100%;
  height: auto;
}
.catalog__categories_item-title {
  color: #000000;
  text-align: center;
  margin: 10px 0;
}
.catalog__categories_item:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.catalog__categories_item:hover .catalog__categories_item-title {
  color: #e04d27;
}
.catalog__all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}
.catalog__all .card {
  width: 23%;
  min-width: 270px;
  margin-bottom: 30px;
}

.tile {
  animation: 1s ease 0s pulse infinite;
  background: #cfcfdf;
  border-radius: 4px;
  float: left;
  min-height: 275px;
  margin: 5px;
  width: 100px;
  transform: scale(1) !important;
  display: none;
}
.tile-active {
  display: block;
}
.tile:hover {
  transform: scale(1) !important;
}

.block:nth-child(1) {
  animation-delay: 0.03s;
}

.block:nth-child(2) {
  animation-delay: 0.06s;
}

.block:nth-child(3) {
  animation-delay: 0.09s;
}

.block:nth-child(4) {
  animation-delay: 0.12s;
}

.block:nth-child(5) {
  animation-delay: 0.15s;
}

.block:nth-child(6) {
  animation-delay: 0.18s;
}

.block:nth-child(7) {
  animation-delay: 0.21s;
}

.block:nth-child(8) {
  animation-delay: 0.24s;
}

.block:nth-child(9) {
  animation-delay: 0.27s;
}

.block:nth-child(10) {
  animation-delay: 0.3s;
}

.block:nth-child(11) {
  animation-delay: 0.33s;
}

.block:nth-child(12) {
  animation-delay: 0.36s;
}

.block:nth-child(13) {
  animation-delay: 0.39s;
}

.block:nth-child(14) {
  animation-delay: 0.42s;
}

.block:nth-child(15) {
  animation-delay: 0.45s;
}

.block:nth-child(16) {
  animation-delay: 0.48s;
}

.block:nth-child(17) {
  animation-delay: 0.51s;
}

.block:nth-child(18) {
  animation-delay: 0.54s;
}

.block:nth-child(19) {
  animation-delay: 0.57s;
}

.block:nth-child(20) {
  animation-delay: 0.6s;
}

.block:nth-child(21) {
  animation-delay: 0.63s;
}

.block:nth-child(22) {
  animation-delay: 0.66s;
}

.block:nth-child(23) {
  animation-delay: 0.69s;
}

.block:nth-child(24) {
  animation-delay: 0.72s;
}

@media (max-width: 1220px) {
  .catalog__all .card {
    width: 27%;
    min-width: 270px;
    margin: 10px 10px 30px;
  }
}
@media (max-width: 992px) {
  .catalog__all .card {
    width: 38%;
  }
}
@media (max-width: 920px) {
  .catalog__categories_box {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .catalog__categories_box .catalog__categories_item {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .catalog__all .card {
    width: 65%;
  }
}
@media (max-width: 678px) {
  .catalog__categories_box .catalog__categories_item {
    width: 55%;
    min-width: 270px;
  }
}
@media (max-width: 400px) {
  .catalog__categories_title {
    font-size: 25px;
  }
}
.breadcrumb_wrap {
  margin: 22px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumb_item {
  color: #515151;
}
.breadcrumb_item svg {
  margin: 0 15px;
  height: 10px;
}
.breadcrumb_item:hover {
  cursor: pointer;
}
.breadcrumb_item:last-child {
  color: #1e1e1e;
}
.breadcrumb_item:last-child svg {
  display: none;
}
.breadcrumb_item:last-child:hover {
  cursor: default;
}

.catalog__wrap {
  margin: 40px 0;
}
.catalog__headre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalog__box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.catalog__box .card {
  min-width: 296px;
  width: 23%;
  margin: 0 10px 40px;
}
.catalog__box .card__img {
  min-height: 190px;
  border-bottom: 1px solid #e0e0e0;
}
.catalog__box .card_main .title a {
  margin: 3px 0;
}

@media (max-width: 1366px) {
  .catalog__box {
    width: calc(100% - 250px);
  }
  .catalog__box .card {
    min-width: 256px;
  }
}
@media (max-width: 1180px) {
  .catalog__box {
    width: calc(100% - 250px);
  }
  .catalog__box .card {
    width: 40%;
    min-width: 240px;
  }
}
@media (max-width: 992px) {
  .catalog__wrap {
    flex-direction: column;
  }
  .catalog__box {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .catalog__headre {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .catalog__headre .breadcrumb_wrap {
    align-self: flex-start;
  }
  .catalog__headre .sort_select {
    align-self: flex-end;
  }
}
@media (max-width: 520px) {
  .breadcrumb_wrap {
    font-size: 14px;
  }
}
.card-list__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-list__main a {
  margin-right: 30px;
}
.card-list__main a:last-child {
  margin-right: 0px;
}
.card-list__related {
  align-items: flex-start;
  min-height: 420px;
  margin: 30px 0;
}
.card-list__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-list__footer_text {
  font-size: 16px;
  color: #e04d27;
  margin: 30px auto;
}
.card-list__footer_text:hover {
  cursor: pointer;
}

.card {
  max-width: 400px;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #e0e0e0;
  min-width: 300px;
  transition: 0.3s;
  padding: 15px;
}
.card:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.card__img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 250px;
  overflow: hidden;
}
.card__img_additional {
  position: absolute;
  z-index: 5;
  font-size: 18px;
  font-weight: 400;
  top: 22px;
  right: 25px;
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.card__img_additional-new {
  background: #ff5722;
  padding: 5px 10px;
}
.card__img_additional-top {
  background: #ff5722;
  padding: 5px 10px;
}
.card__img_additional div:first-child {
  margin-left: 15px;
}
.card__img img {
  z-index: 3;
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.card_main {
  margin: 10px auto 0;
}
.card_main__text {
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.card_main .subtitle {
  color: #757575;
  font-size: 14px;
}
.card_main .title {
  font-size: 16px;
  font-weight: 600;
  color: #1e1e1e;
  transition: 0.3s;
}
.card_main .title:hover {
  transition: 0.3s;
  color: #e04d27;
}
.card_main .title a {
  color: #1e1e1e;
}
.card_main .price {
  font-size: 19px;
  color: #e04d27;
}
.card_main__control-btn {
  display: inline-block;
  margin: 15px 0;
}
.card__related {
  border: none;
}
.card__related_main__text {
  min-height: 75px;
  justify-content: space-between;
}
.card__related_main__text .price {
  font-size: 19px;
  color: #e04d27;
}

@media (max-width: 1400px) {
  .card__img_additional {
    position: absolute;
    z-index: 5;
    font-size: 14px;
    font-weight: 400;
    top: 22px;
    right: 25px;
    color: #fff;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .card__img_additional-new {
    background: #ff5722;
    padding: 5px 10px;
  }
  .card__img_additional-top {
    background: #ff5722;
    padding: 5px 10px;
  }
  .card__img_additional div:first-child {
    margin-left: 15px;
  }
}
@media (max-width: 992px) {
  .card-list__main {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .card-list__main .card {
    margin: 30px 10px;
  }
  .card-list__main .card:last-child {
    margin: 30px 10px;
  }
}
@media (max-width: 560px) {
  .card {
    min-width: 210px;
  }
}
.servants__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}
@media (max-width: 1150px) {
  .servants__wrap {
    justify-content: center;
  }
}
.servants__item {
  max-width: 260px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}
.servants__item:last-child {
  margin-right: 0px;
}
.servants__item_img {
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.servants__item_main {
  text-align: center;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.servants__item_main .title {
  font-size: 24px;
  color: #000000;
}
.servants__item_main .subtitle {
  font-size: 12px;
  color: #000000;
}

.please__wrap {
  display: grid;
  grid-template-columns: 40%;
  grid-template-rows: repeat(2, minmax(200px, 310px));
  grid-gap: 35px;
  position: relative;
  grid-column-gap: 60px;
}
.please__item {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}
.please__item:hover .please__item_main {
  transition: 0.3s;
  height: 190px;
}
.please__item:hover .please__item_main-horizont {
  transition: 0.3s;
  height: 100%;
  width: 55%;
}
.please__item_title {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
}
.please__item_text {
  font-size: 12px;
  color: #ececec;
}
.please__item_main {
  transition: 0.3s;
  position: absolute;
  bottom: 0;
  height: 170px;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
  /* padding: 50px; */
  padding: 0 40px;
}
.please__item_main-horizont {
  right: 0;
  width: 50%;
  height: 100%;
  padding: 25px 15px;
  justify-content: flex-end;
}
.please__item_text {
  color: #fff;
}
.please__item_img {
  height: 100%;
  width: auto;
  position: relative;
}
.please__item_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: auto;
}
.please__item-big {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.please__item-big .please__item_img img {
  height: 100%;
  width: auto;
  display: block;
}

@media (max-width: 1366px) {
  .please__wrap {
    grid-template-columns: 45%;
    grid-column-gap: 40px;
  }
  .please__item_title {
    font-size: 20px;
  }
}
@media (max-width: 1100px) {
  .please__wrap {
    grid-template-columns: 45%;
    grid-column-gap: 40px;
  }
  .please__item_title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .please__wrap {
    grid-template-columns: 80%;
    grid-column-gap: 40px;
    justify-content: center;
    grid-template-rows: auto;
  }
  .please__item-big {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .please__item-big .please__item_img img {
    width: 100%;
    height: auto;
  }
  .please__item_main {
    position: absolute;
    bottom: 0;
    height: 170px;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
  }
  .please__item_title {
    font-size: 20px;
  }
  .please__item_img img {
    width: 105%;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
@media (max-width: 768px) {
  .please__wrap {
    grid-template-columns: 90%;
  }
  .please__item_main {
    height: 110px;
    padding: 0 10px;
  }
}
.contact-us__wrap {
  display: grid;
  grid-template-columns: 1fr minmax(400px, 430px);
  grid-gap: 45px;
  margin-bottom: 30px;
}
.contact-us__map {
  width: 100%;
  height: 100%;
}
.contact-us__map iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .contact-us__wrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .contact-us__map {
    margin-top: 10px;
    height: 60vw;
  }
}
.form__auth {
  margin: 50px 80px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.88);
  -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.88);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.88);
  display: none;
}
.form__auth_wrap {
  display: none;
  padding: 50px 80px;
  width: 27%;
  min-width: 550px;
}
.form__auth_header-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #1e1e1e;
}
.form__auth_header-subtitle {
  font-size: 16px;
  color: #1e1e1e;
  text-align: center;
}
.form__auth_footer {
  width: 100%;
  margin: 15px 0;
}
.form__auth_footer-subtitle {
  text-align: justify;
  font-size: 14px;
}
.form__auth_footer-subtitle a {
  color: #e04d27;
}
.form__auth_main .group_box {
  width: 100%;
  margin: 20px auto;
}
.form__auth_main .group_box label {
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  line-height: 1;
  letter-spacing: normal;
  text-transform: unset;
  margin-bottom: 8px;
}
.form__auth_main .group_box label span {
  color: #e04d27;
  font-size: 14px;
}
.form__auth_main .group_box input {
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #fefefe;
  padding: 15px 10px;
}
.form__auth_main .group_box .error {
  margin: 5px 0 4px;
}
.form__auth_main .group_box_error {
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-transform: unset;
  font-size: 14px !important;
  color: red !important;
  margin: -10px 0 20px;
}
.form__auth__control_btn {
  padding: 15px 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.88px;
  text-align: center;
}
.form__auth__control__info {
  font-size: 14px;
  text-align: center;
  color: #8d8d8d;
  font-size: 16px;
  margin: 15px 0;
}
.form__auth__control__info a {
  color: #e04d27;
  margin: 0 10px;
}

.form-order-credit {
  display: none !important;
}

@media (max-width: 650px) {
  .fancybox-content {
    max-width: 100%;
  }
  .form__auth_wrap {
    padding: 22px 40px;
    width: 95%;
    min-width: 300px;
  }
}
@media (max-width: 650px) {
  .form__auth_main .group_box {
    max-width: 520px;
    width: 100%;
    margin: 20px auto;
  }
}
@media (max-width: 400px) {
  .form__auth_wrap {
    padding: 12px 20px;
    width: 95%;
    min-width: 300px;
  }
  .form__auth_main .group_box {
    max-width: 520px;
    width: 100%;
    margin: 20px auto;
  }
}
.contact__wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  margin: 40px 0;
}
.contact__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact__item_title {
  font-size: 30px;
  line-height: 2.37;
  color: #000000;
}
.contact__item_text {
  font-family: NotoSans;
  font-size: 16px;
  line-height: 1.63;
  text-align: justify;
  color: #95999d;
}
.contact__social {
  flex-direction: column;
  display: flex;
}
.contact__social_item {
  display: inline-flex;
  align-items: center;
  margin: 7px 0;
}
.contact__social_item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.contact__social_item-icon svg {
  width: 80%;
  height: auto;
}
.contact__social_item-name {
  font-size: 16px;
  color: #95999d;
}
.contact__form_wrap {
  margin-top: -50px;
}
.contact__map {
  display: block;
  height: 100%;
}

@media (max-width: 992px) {
  .contact__social_wrap {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .contact__social {
    margin-top: -20px;
  }
  .contact__form_wrap {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: -10px;
  }
  .contact__wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin: 40px 0;
  }
  .contact__map {
    display: block;
    height: 100%;
    height: 50vw;
    min-height: 300px;
  }
  .contact__map_wrap {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 992px) {
  .contact__social_wrap {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .contact__form_wrap {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .contact__wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin: 40px 0;
    grid-column-gap: 0px;
  }
  .contact__map {
    display: block;
    height: 100%;
    height: 50vw;
    min-height: 300px;
  }
  .contact__map_wrap {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
.secrch {
  margin: 30px 0;
  min-height: 600px;
}
.secrch_title {
  font-size: 30px;
  color: #000000;
}
.secrch_title_requesdt {
  font-size: 45px;
  font-weight: 600;
  line-height: 1.6;
  color: #2f3134;
}
.secrch__wrap .catalog__all {
  margin-top: 30px;
}
.secrch__wrap_container_feil {
  display: none;
}
.secrch__wrap_container_feil-active {
  display: block;
}

@media (max-width: 1520px) {
  .secrch {
    min-height: 500px;
  }
}
@media (max-width: 1520px) {
  .secrch {
    min-height: 400px;
  }
}
@media (max-width: 1360px) {
  .secrch {
    min-height: 300px;
  }
}
.about {
  margin: 30px 0;
}
.about_title {
  font-size: 30px;
  line-height: 2;
  color: #000000;
}
.about__wrap {
  margin: 20px 0;
}
.about__wrap_header img {
  width: 100%;
}
.about__wrap_main {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}
.about__section {
  width: 47%;
}
.about__section img {
  width: 100%;
}
.about_text {
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  color: #95999d;
}
.about__our_team_item {
  max-width: 300px;
  margin: 20px;
}
.about__our_team_item:first-child {
  margin-left: 0;
}
.about__our_team_item:last-child {
  margin-right: 0;
}
.about__our_team_item img {
  width: 100%;
  height: auto;
  min-height: 230px;
}
.about__our_team_item-title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.about__our_team_item-subtitle {
  font-size: 16px;
  line-height: 1.63;
  color: #95999d;
}
.about__box {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1400px) {
  .about__our_team_item-img {
    margin-bottom: 20px;
  }
  .about__our_team_item-title {
    font-size: 19px;
    font-weight: 600;
    color: #000000;
  }
  .about__our_team_item-subtitle {
    font-size: 15px;
  }
}
@media (max-width: 1100px) {
  .about__our_team_item-title {
    font-size: 17px;
  }
  .about__our_team_item-subtitle {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .about__wrap_main {
    justify-content: space-between;
    flex-direction: column;
  }
  .about__section {
    width: 100%;
  }
  .about_text {
    margin-bottom: 20px;
  }
}
@media (max-width: 820px) {
  .about__box {
    flex-wrap: wrap;
  }
  .about__our_team_item {
    margin: 0;
    width: 45%;
    min-width: 230px;
  }
}
@media (max-width: 550px) {
  .about__box {
    justify-content: space-around;
  }
  .about__our_team_item {
    width: 90%;
    max-width: 300px;
    margin: 20px 0;
  }
}
.blog-categorie {
  margin: 30px 0 50px;
}
.blog-categorie_title {
  font-size: 30px;
  line-height: 2;
  color: #000000;
}
.blog-categorie_item {
  position: relative;
  max-height: 600px;
  height: 100%;
  transition: 0.3s;
  overflow: hidden;
}
.blog-categorie_item:hover {
  transition: 0.3s;
  transform: scale(1.03);
}
.blog-categorie_item-img {
  position: relative;
  max-height: 600px;
  height: 100%;
}
.blog-categorie_item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-categorie_item-img_bg {
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 600px;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.blog-categorie_item-btn {
  display: inline-block;
  padding: 10px 30px;
  margin: 10px 0;
}
.blog-categorie_item-main {
  grid-column-start: 1;
  grid-column-end: 3;
}
.blog-categorie_item_box {
  position: absolute;
  bottom: 10%;
  width: 80%;
  left: 10%;
  max-width: 400px;
  min-height: 150px;
}
.blog-categorie_item-title {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
}
.blog-categorie_item-text {
  font-size: 12px;
  color: #ffffff;
  margin: 10px 0;
}
@media (max-width: 1000px) {
  .blog-categorie_item {
    max-height: 400px;
  }
  .blog-categorie_item-img {
    max-height: 400px;
  }
  .blog-categorie_item-img_bg {
    max-height: 400px;
  }
}
@media (max-width: 720px) {
  .blog-categorie_item {
    max-height: 300px;
  }
  .blog-categorie_item-img {
    max-height: 300px;
  }
  .blog-categorie_item-img_bg {
    max-height: 300px;
  }
}
@media (max-width: 500px) {
  .blog-categorie_item {
    max-height: 250px;
  }
  .blog-categorie_item-img {
    max-height: 250px;
  }
  .blog-categorie_item-img_bg {
    max-height: 250px;
  }
}
.blog-categorie__wrap {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.blog-categorie_item-main .blog-categorie_item_box {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 992px) {
  .blog-categorie_item-title {
    font-size: 33px;
  }
  .blog-categorie__wrap {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (max-width: 840px) {
  .blog-categorie__wrap {
    grid-template-columns: 1fr;
  }
  .blog-categorie_item-main {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .blog-categorie_item-main .blog-categorie_item_box {
    position: absolute;
    bottom: 10%;
    width: 80%;
    left: 10%;
    max-width: 400px;
    min-height: 150px;
    text-align: inherit;
    top: auto;
    transform: initial;
  }
}
@media (max-width: 840px) {
  .blog-categorie_item-title {
    font-size: 25px;
  }
  .blog-categorie_item_box {
    bottom: 5%;
    width: 90%;
    left: 5%;
  }
  .blog-categorie_item-main {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .blog-categorie_item-main .blog-categorie_item_box {
    bottom: 5%;
    width: 90%;
    left: 5%;
  }
  .blog-categorie_item-img {
    overflow: hidden;
  }
}
.profil {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
}
.profil__block {
  font-size: 16px;
  width: 29%;
  padding: 30px 35px;
  box-sizing: border-box;
  margin-bottom: 40px;
  border: 1px solid #ccc;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.profil__avatar {
  text-align: center;
}
.profil__avatar_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  box-shadow: 0 10px 40px -2px rgba(214, 214, 214, 0.7);
}
.profil__navigation_link, .profil__navigation_link-a {
  padding: 6px 0 10px 0;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  cursor: pointer;
  color: #000;
}
.profil__navigation_link-active, .profil__navigation_link-a-active {
  font-weight: 600;
  color: #EC3A34;
}
.profil__navigation_link-active span svg path, .profil__navigation_link-a-active span svg path {
  fill: #EC3A34;
}
.profil__navigation_link:hover, .profil__navigation_link-a:hover {
  color: #EC3A34;
}
.profil__navigation_link:hover span svg path, .profil__navigation_link-a:hover span svg path {
  fill: #EC3A34;
}
.profil__navigation_link span, .profil__navigation_link-a span {
  width: 15px !important;
  height: auto;
  margin-right: 15px;
  display: inline-block;
}
.profil__navigation_link span svg, .profil__navigation_link-a span svg {
  width: 100% !important;
  height: auto;
}
.profil__order_item {
  margin: 25px 0;
  padding: 1px 0;
}
.profil__order_item-title {
  text-transform: uppercase;
  padding: 5px 0px;
  text-align: center;
}
.profil__order_item h4 {
  margin: 0;
  padding-bottom: 10px;
}
.profil__order_item table {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.profil__info {
  width: 70%;
  padding: 40px;
  box-sizing: border-box;
}
.profil__content {
  display: none;
}
.profil__content_order-not {
  display: flex;
  align-items: center;
}
.profil__content_order-not span {
  margin-right: 10px;
}
.profil__content_order-yes {
  max-width: 900px;
  margin: 0 auto 50px;
  padding: 20px 0;
}
.profil__content_title {
  margin: 0;
  text-align: center;
  letter-spacing: 3px;
}
.profil__content-active {
  display: block;
}
.profil__content_link {
  color: #EC3A34;
}
.profil__content_link:hover {
  font-weight: 500;
}

.profile .form-group {
  width: 47%;
  display: flex;
  flex-direction: column;
}
.profile .form-group span {
  margin: 5px 0;
  font-size: 14px;
}
.profile .input-group {
  margin: 10px 0;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.profil__nav3 .ordering {
  margin: 0 auto;
}

.table thead tr th {
  padding: 5px;
  background: rgba(198, 206, 215, 0.431372549);
  border-right: 1px solid rgba(198, 206, 215, 0.431372549);
}
.table tbody tr td {
  border: solid 1px #e5e5e5;
}
.table tbody tr td:first-child {
  padding: 5px;
  background: rgba(198, 206, 215, 0.431372549);
}

.td_status {
  width: 120px;
  padding: 5px;
}

@media only screen and (max-width: 960px) {
  .profil {
    flex-direction: column;
    align-items: center;
  }
  .profil__block {
    height: 350px;
    width: 60%;
    padding: 30px 20px;
  }
  .profil__info {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .profil {
    max-width: 100%;
  }
  .profil__order_item {
    overflow: auto;
  }
  .profil__order_item table {
    min-width: 500px;
    padding: 0 0 10px 0;
  }
  .profil__info {
    width: 100%;
    padding: 0;
  }
  .form-row {
    flex-direction: column;
  }
  .form-row .form-group {
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .profil__order_item h4 {
    font-size: 14px;
  }
  .profil__info {
    width: 100%;
    padding: 20px 0;
  }
}
.blog-catalog-title {
  font-size: 30px;
  line-height: 2;
  color: #000000;
}
.blog-catalog__header {
  display: flex;
  justify-content: space-between;
}
.blog-catalog__header-title {
  font-size: 30px;
  line-height: 1.4;
  color: #000000;
}
.blog-catalog__header .blog-catalog__header-search {
  position: relative;
}
.blog-catalog__header .blog-catalog__header-search .search__form {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #2a3860;
}
.blog-catalog__header .blog-catalog__header-search .search__form .search__input {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  margin-right: 40px;
}
.blog-catalog__header .blog-catalog__header-search .search__form .search__input:focus {
  border: none;
}
.blog-catalog__header .blog-catalog__header-search .search__form .search__input_blog {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  margin-right: 40px;
}
.blog-catalog__header .blog-catalog__header-search .search__form .search__input_blog:focus {
  outline: none;
}
.blog-catalog__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.blog-catalog__item {
  max-width: 300px;
  margin: 20px 0;
  position: relative;
  transition: 0.3s;
}
.blog-catalog__item:hover {
  transition: 0.3s;
  transform: scale(1.05);
}
.blog-catalog__item_img img {
  width: 100%;
  display: block;
}
.blog-catalog__item_img-bg {
  display: block;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
}
.blog-catalog__item_date {
  padding: 5px 15px;
  background: #e04d27;
  border-radius: 50px;
  display: inline-block;
}
.blog-catalog__item_title {
  font-weight: 600;
  font-size: 14px;
  margin: 8px 0;
}
.blog-catalog__item_read-on {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.blog-catalog__item_read-on .icon {
  height: 16px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  transition: 0.2s;
}
.blog-catalog__item_read-on .icon svg {
  height: 100%;
  width: auto;
}
.blog-catalog__item_read-on:hover {
  color: #fff;
}
.blog-catalog__item_read-on:hover .icon {
  transition: 0.2s;
  margin-left: 15px;
}
.blog-catalog__item_main {
  position: absolute;
  width: 80%;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  bottom: 10%;
  left: 5%;
}
.blog-catalog__control {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-catalog__pagen {
  display: flex;
  align-items: center;
}
.blog-catalog__pagen-item {
  opacity: 0.4;
  font-size: 16px;
  color: #000000;
  padding: 1px 2px;
  transition: 0.3s;
}
.blog-catalog__pagen-item:hover {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}
.blog-catalog__pagen-item-active {
  opacity: 1;
}
.blog-catalog__pagen-control {
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.blog-catalog__pagen-control svg {
  height: 100%;
}
.blog-catalog__pagen-control svg path {
  fill: #2a3860;
}
.blog-catalog__pagen-control:hover {
  transition: 0.3s;
  cursor: pointer;
}
.blog-catalog__pagen-control:hover svg path {
  fill: #F49823;
}

@media (max-width: 640px) {
  .blog-catalog__header {
    flex-direction: column;
  }
  .blog-catalog__header-search {
    margin: 10px 0;
  }
}
.post_depail img {
  /* max-width: 100%; */
  height: auto !important;
  padding: 10px;
}

.product__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__main_section {
  width: 47%;
}
.product__main_section_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.product__main_section_header .in-stock {
  font-size: 16px;
  line-height: 1.63;
  text-align: justify;
  color: #16d303;
}
.product__main_section_header .un-stock {
  font-size: 16px;
  line-height: 1.63;
  text-align: justify;
  color: red;
}
.product__main_section_header .title {
  font-size: 45px;
  line-height: 1.6;
  color: #2f3134;
}
.product__main_section_header .info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  flex-direction: column-reverse;
}
.product__main_section_header .info__price {
  display: flex;
  align-items: flex-start;
}
.product__main_section_header .info__price_wrap {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product__main_section_header .info__price-convert {
  margin: 18px 0 5px;
  align-items: baseline;
}
.product__main_section_header .info__price-convert .info__price_valid {
  font-size: 22px;
}
.product__main_section_header .info__price-convert span {
  color: #999999;
  font-size: 14px;
  margin-left: 5px;
}
.product__main_section_header .info__price_valid {
  font-size: 30px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: justify;
  color: #e04d27;
}
.product__main_section_header .info__price_invalid {
  font-size: 22px;
  line-height: 1;
  color: #999999;
  text-decoration: line-through;
  margin: 0 15px;
}
.product__main_section_header .info__star {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.product__main_section_header .info__star_wrap {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.product__main_section_header .info__star_item {
  height: 16px;
  position: relative;
}
.product__main_section_header .info__star_item .star {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
  display: block;
}
.product__main_section_header .info__star_item .star_full {
  display: none;
}
.product__main_section_header .info__star_item .star_full-active {
  display: block;
}
.product__main_section_header .info__star_item .star_full-active-click {
  display: block;
}
.product__main_section_header .info__star_item-active .star_full {
  display: block;
}
.product__main_section_main {
  margin: 25px 0;
}
.product__group_description_title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 3;
  color: #757575;
}
.product__group_description_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 2px solid #f9f8f6;
}
.product__group_description_item div {
  width: 45%;
}
.product__group_description_name {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.6;
  color: #000000;
}
.product__group_description_value {
  font-size: 16px;
  line-height: 1.25;
  color: #757575;
}
.product__group_plus, .product__group_minus {
  width: 38px;
  height: 38px;
  background-color: #f3f3f3;
  color: #33333a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
}
.product__group_plus:hover, .product__group_minus:hover {
  transition: 0.3s;
  background: #e04d27;
  color: #fff;
}
.product__quantity {
  display: inline-flex;
  border: solid 1px #dedede;
}
.product__quantity_count {
  width: 38px;
  height: 38px;
  color: #33333a;
  text-align: center;
  font-size: 18px;
  border: none;
}
.product__control {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  flex-wrap: wrap;
}
.product__control * {
  margin: 10px 0;
}
.product__control:hover {
  cursor: pointer;
}
.product__control .btn {
  max-width: 245px;
  width: 40%;
}
.product__description_wrap {
  margin: 20px 0;
}
.product__description__wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-height: 250px;
  overflow: hidden;
  transition: 0.4s;
}
.product__description__wrap-active {
  transition: 0.4s;
  max-height: 10000px;
}
.product__description__wrap-active .product__description__wrap_bg {
  bottom: 400%;
  transition: 1s;
}
.product__description__wrap_bg {
  transition: 1s;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.68) 38%, rgba(255, 255, 255, 0.77) 43%, rgb(255, 255, 255) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(38%, rgba(255, 255, 255, 0.68)), color-stop(43%, rgba(255, 255, 255, 0.77)), color-stop(100%, rgb(255, 255, 255)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.68) 38%, rgba(255, 255, 255, 0.77) 43%, rgb(255, 255, 255) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.68) 38%, rgba(255, 255, 255, 0.77) 43%, rgb(255, 255, 255) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.68) 38%, rgba(255, 255, 255, 0.77) 43%, rgb(255, 255, 255) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.68) 38%, rgba(255, 255, 255, 0.77) 43%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#ffffff", GradientType=0 );
}
.product__description__section {
  width: 44%;
}
.product__description__section_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.product__description__section .product__group_description {
  margin: 30px 0;
}
.product__description_control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.product__description_control .btn {
  width: 200px;
}

.swiper-slide {
  height: auto;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  min-height: 100px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .swiper-slide img {
    min-height: 250px;
  }
}

.gallery-thumbs {
  padding: 15px 0 0;
}
.gallery-thumbs .swiper-wrapper .swiper-slide img {
  height: 115px;
}
.gallery-thumbs .swiper-wrapper .swiper-slide-thumb-active {
  border: 1px solid #e04d27 !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-image: none !important;
  height: 17px;
}
.swiper-button-next svg,
.swiper-button-prev svg {
  height: 100%;
}

.swiper-pagination {
  bottom: 0;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border: solid 2px #d8d8d8;
  background-color: transparent;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #d8d8d8;
  opacity: 1;
}

.product_reviews {
  display: flex;
  justify-content: space-between;
}
.product_reviews-list {
  display: flex;
  flex-direction: column;
  width: 68%;
  max-height: 600px;
  overflow: auto;
}
.product_reviews-form {
  width: 30%;
  border: 1px solid #dbdbdb;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.reviews {
  background: #fdfdfd;
  border: 1px solid #dbdbdb;
  padding: 20px 10px;
  margin-bottom: 10px;
}
.reviews_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
}
.reviews-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
}
.reviews__left-part {
  display: flex;
}
.reviews-author {
  color: #1e1e1e;
  font-weight: 600;
}
.reviews-date {
  font-size: 15px;
  color: grey;
  margin-left: 20px;
}
.reviews-main {
  margin: 20px 0;
}

.info__star_wrap {
  display: flex;
}

.info__star_item {
  width: 22px;
}

.tab {
  display: flex;
  flex-direction: column;
}
.tab_header {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.tab_header_item {
  padding: 15px 30px;
  margin-right: 10px;
  border: 1px solid #dbdbdb;
  position: relative;
  transition: 0.2s;
}
.tab_header_item:hover {
  transition: 0.2s;
  cursor: pointer;
  background: rgb(224, 77, 39);
  color: #fff;
}
.tab_header_item:hover span {
  transition: 0.1s;
  background: transparent;
  color: #fff;
}
.tab_header_item span {
  transition: 0.2s;
  height: 20px;
  width: 20px;
  background: transparent;
  position: absolute;
  border-radius: 50%;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.tab_header_item-active {
  transition: 0.2s;
  cursor: pointer;
  background: rgb(224, 77, 39);
  color: #fff;
}
.tab_header_item-active span {
  transition: 0.1s;
  background: transparent;
  color: #fff;
}
.tab_main {
  display: none;
}
.tab_main-active {
  display: block;
}

.rating {
  display: flex;
}
.rating__item {
  height: 17px;
  width: 17px;
  position: relative;
}
.rating__item .star {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
  display: block;
}
.rating__item .star_full {
  display: none;
}
.rating__item .star_full-active {
  display: block;
}
.rating__item .star_full-active-click {
  display: block;
}

.right__right-part .info__star_wrap {
  display: flex;
}
.right__right-part .info__star_wrap .info__star_item {
  width: 22px;
  position: relative;
}
.right__right-part .info__star_wrap .info__star_item .star {
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
}
.right__right-part .info__star_wrap .info__star_item .star_full {
  display: none;
}
.right__right-part .info__star_wrap .info__star_item-active .star_full {
  display: block;
}

@media (max-width: 992px) {
  .product_reviews {
    flex-direction: column;
  }
  .product_reviews-list {
    width: 100%;
    margin-bottom: 20px;
  }
  .product_reviews-form {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    margin: 0 auto;
  }
  .product__main {
    flex-direction: column;
  }
  .product__main_section:last-child {
    width: 75%;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .btn-order-buy {
    margin: 10px auto;
  }
  .group_box .select {
    min-width: 130px;
  }
  .product__description__section_wrap {
    flex-direction: column;
  }
  .product__description__section_wrap .product__description__section {
    width: 100%;
  }
  .product__description__wrap {
    flex-direction: column;
  }
  .product__description__section {
    width: 90%;
    margin: auto;
  }
  .product__main {
    flex-direction: column;
  }
  .product__main_section:first-child {
    width: 75%;
    margin-top: 20px;
  }
  .product__main_section_header .title {
    font-size: 26px;
    line-height: 1.6;
    color: #2f3134;
  }
  .product__main_section_header .info__price-convert .info__price_valid {
    font-size: 18px;
  }
  .product__main_section_header .info__price_valid {
    font-size: 20px;
  }
  .product__main_section_header .info__price_invalid {
    font-size: 14px;
  }
}
@media (max-width: 550px) {
  .product__group_description_item {
    flex-direction: column;
  }
  .product__group_description_item .product__group_description_name, .product__group_description_item .product__group_description_value {
    width: 100%;
  }
}
@media (max-width: 490px) {
  .product_wrap .product__main {
    flex-direction: column;
  }
  .product_wrap .product__main_section {
    width: 90%;
  }
  .product__control .btn {
    max-width: 245px;
    width: 47%;
  }
}
@media (max-width: 400px) {
  .info__star {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .product__control {
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
  }
  .product__control .btn {
    max-width: 245px;
    width: 100%;
    margin: 10px;
  }
}
.basket {
  display: none;
  margin: 10px 0 60px;
  min-height: 400px;
  align-items: center;
}
.basket_empty {
  padding: 40px 0;
}
.basket_wrap {
  min-height: 150px;
  margin: 30px auto;
  display: none;
}
.basket_wrap-active {
  display: block;
}
.basket-active {
  display: block;
}
.basket__title {
  font-size: 30px;
  line-height: 2.37;
  letter-spacing: -0.33px;
  color: #000000;
  text-align: center;
}
.basket__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.basket__step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  position: relative;
}
.basket__step .step {
  width: 25px;
  height: 25px;
  border: solid 1px #e5e5e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 1;
  background: #fff;
  cursor: default;
  cursor: pointer;
}
.basket__step .step_bg {
  position: absolute;
  height: 1px;
  background-color: #e5e5e5;
  width: 100%;
  z-index: 0;
}
.basket__step .step-chesk {
  display: none;
}
.basket__step .step-action {
  border: 1px solid #e04d27;
  background-color: #e04d27;
  color: #fff;
  font-weight: 600;
}
.basket__step .step-action .step-number {
  display: block;
}
.basket__group_col-1 {
  width: 70%;
  display: flex;
}
.basket__group_col-1 .basket_item_img_link {
  width: 20%;
}
.basket__group_col-2 {
  width: 30%;
  display: flex;
}
.basket__group_col-2_mod {
  display: flex;
  width: 100%;
  align-items: center;
}
.basket__group_title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.basket__group_title .item_heder {
  width: 75%;
}
.basket__group_title .count_heder {
  width: 40%;
  text-align: center;
}
.basket__group_title .price_heder {
  width: 40%;
  text-align: center;
}
.basket__group_title .delete_product {
  width: 75px;
}
.basket__group_title div {
  font-size: 12px;
  letter-spacing: 1px;
  color: #8d8d8d;
  text-transform: uppercase;
}
.basket__group_title .f-2 {
  display: flex;
  width: 30%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.basket__main {
  position: relative;
}
.basket__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.basket__item_group {
  display: flex;
  align-items: center;
}
.basket__item_img {
  width: 100%;
}
.basket__item_img img {
  width: 100%;
  height: auto;
  max-width: 16 0px;
}
.basket__item_title {
  display: block;
  width: 80%;
}
.basket__item_title div {
  font-size: 20px;
  color: #000000;
  margin: 20px;
}
.basket__item_title:hover div {
  transition: 0.3s;
  color: #e04d27;
}
.basket__item_quantity {
  width: 40%;
  text-align: center;
}
.basket__item_quantity input {
  border: solid 1px #e5e5e5;
  width: 60px;
}
.basket__item_price {
  width: 40%;
  text-align: center;
}
.basket__item_delete {
  width: 75px;
  text-align: center;
}
.basket__item_delete_ico {
  cursor: pointer;
}
.basket__item_delete_ico:hover svg path {
  transition: 0.3s;
  fill: #e04d27;
}
.basket__item_delete svg {
  height: 20px;
}
.basket__item_delete svg path {
  transition: 0.3s;
}
.basket__footer {
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
}
.basket__footer_price {
  margin: 27px 0;
  display: flex;
}
.basket__footer_price-title {
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 1px;
  color: #9b9b9b;
  text-transform: uppercase;
  margin: 0 10px;
}
.basket__footer_price-value {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 1.5px;
  color: #000000;
}
.basket__control {
  display: flex;
  margin: 20px 0;
  justify-content: flex-end;
}
.basket__control .btn {
  width: 243px;
  border-radius: 2px;
}

.step-done .step-number {
  display: none !important;
}
.step-done .step-chesk {
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-done .step-chesk svg {
  height: 100%;
}

.contact_user {
  display: none;
}
.contact_user-active {
  display: block;
  margin: 30px 0;
}
.contact_user__wrap {
  width: 50%;
  margin: 0 auto;
}
.contact_user__control {
  display: flex;
  justify-content: flex-end;
}
.contact_user__control .btn {
  display: inline-block;
  width: 240px;
}

.group_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 22px 0;
}
.group_box {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.group_box label {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  margin-bottom: 8px;
}
.group_box input {
  padding: 7px 5px;
  border: solid 1px #e5e5e5;
  font-size: 16px;
  color: #000000;
}

@media (max-width: 1366px) {
  .contact_user__wrap {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .contact_user__wrap {
    width: 550px;
  }
}
@media (max-width: 768px) {
  .basket__group_col-1 .basket_item_img_link {
    min-width: 120px;
  }
  .basket__group_title .count_heder {
    display: none;
  }
  .basket__group_title .price_heder {
    display: none;
  }
  .basket__item {
    flex-direction: column;
  }
  .basket__group_col-1 {
    width: 90%;
    align-items: center;
    justify-content: space-between;
  }
  .basket__group_col-2 {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  .basket__group_col-2_mod {
    width: 50%;
    min-width: 250px;
  }
  .basket__item_title {
    display: flex;
    justify-content: flex-end;
  }
  .basket__item {
    flex-direction: column;
    margin: 20px 0 30px;
  }
}
@media (max-width: 640px) {
  .contact_user__wrap {
    width: 90%;
  }
}
@media (max-width: 520px) {
  .basket__step {
    width: 75%;
  }
  .group_row {
    flex-direction: column;
    margin: 0px;
  }
  .group_box {
    max-width: 300px;
    width: 90%;
    margin: 6px 0;
  }
  .contact_user__control {
    margin: 25px;
  }
  .basket__group_col-1 {
    width: 100%;
  }
  .basket__item_title div {
    font-size: 16px;
    margin: 10px;
  }
}
.offerta {
  padding: 100px 0;
}
.offerta h3,
.offerta p {
  margin: 0;
}
.offerta a {
  color: #e04d27;
  padding: 2px;
}
.offerta a:hover {
  background-color: #e04d27;
  color: #fff;
  border-radius: 2px;
}
.offerta .section {
  padding: 20px 0;
}
.offerta__title {
  text-align: center;
  color: #2f3134;
  font-size: 40px;
  line-height: 1.13;
  font-weight: 500;
  margin: 0 0 20px;
}
.offerta__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.offerta__info-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0;
  padding-left: 20px;
}
@media (max-width: 720px) {
  .offerta {
    padding: 20px 0;
  }
  .offerta__title {
    margin: 0 0 10px;
    font-size: 20px;
  }
  .offerta .section {
    padding: 15px 0;
  }
  .offerta .section_title {
    font-size: 16px;
  }
  .offerta__info {
    gap: 15px;
    margin-top: 20px;
  }
  .offerta__info-text {
    font-size: 14px;
  }
  .offerta__info-list {
    gap: 10px;
  }
}

.privacy-policy {
  padding: 100px 0;
}
.privacy-policy h3,
.privacy-policy p {
  margin: 0;
}
.privacy-policy a {
  color: #e04d27;
  padding: 2px;
}
.privacy-policy a:hover {
  background-color: #e04d27;
  color: #fff;
  border-radius: 2px;
}
.privacy-policy .section {
  padding: 20px 0;
}
.privacy-policy__title {
  text-align: center;
  text-transform: uppercase;
  color: #2f3134;
  font-size: 40px;
  line-height: 1.13;
  font-weight: 500;
  margin: 0 0 20px;
}
.privacy-policy__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.privacy-policy__info-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0;
  padding-left: 20px;
}
@media (max-width: 720px) {
  .privacy-policy {
    padding: 20px 0;
  }
  .privacy-policy__title {
    margin: 0 0 10px;
    font-size: 20px;
  }
  .privacy-policy .section {
    padding: 15px 0;
  }
  .privacy-policy .section_title {
    font-size: 16px;
  }
  .privacy-policy__info {
    gap: 15px;
    margin-top: 20px;
  }
  .privacy-policy__info-text {
    font-size: 14px;
  }
  .privacy-policy__info-list {
    gap: 10px;
  }
}