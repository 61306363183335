// main: style.sass

.form {
  &__row {
    &:first-child {
      margin-top: 0;
    }
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    & label {
      font-size: 14px;
      font-weight: 500;
      color: #757575;
      margin-bottom: 8px;

      & span {
        color: #e04d27;
      }
    }

    & input {
      font-size: 18px;
      padding: 10px;
      border-radius: 3px;
      border: solid 1px #cccccc;
      background-color: #fefefe;
    }
    & textarea {
      width: 100%;
    }
  }
}
.form__agree_terms {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  & [type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 10px !important;
  }
}
.hidden {
  display: none;
}
.thank_you {
  padding: 0 30px;
  text-align: center;
  // text-transform: capitalize;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  &__wrap {
    text-align: center;
    padding: 160px 0;
    & .btn {
      margin: 30px 0;
    }
  }
}
.link {
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: #e04d27;
    transition: 0.3s;

    & svg path {
      fill: #e04d27;
      transition: 0.3s;
    }
  }
}

.irs--flat {
  & .irs-bar {
    background: transparent;
  }

  & .irs-line {
    height: 9px;
  }

  & .irs-handle {
    top: 21px;
    border-radius: 2px;
    width: 15px;
    height: 19px;
    background-color: #878786;

    & i {
      display: none !important;
    }
  }
}

label.error {
  font-size: 12px !important;
  color: red !important;
}

.loader {
  position: absolute;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  left: 25px;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff,
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff,
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5),
      2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff,
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff,
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5),
      0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff,
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff,
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0 -2.6em 0 0 #ffffff, 1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0 #ffffff,
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7), 2.5em 0 0 0 #ffffff,
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5),
      2.5em 0 0 0 rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0 #ffffff,
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7), 0 2.5em 0 0 #ffffff,
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5),
      0 2.5em 0 0 rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0 #ffffff,
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7), -2.6em 0 0 0 #ffffff,
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0 #ffffff;
  }
}

.select {
  position: relative;
  border: solid 1px #e5e5e5;
  color: #000000;
  min-width: 260px;
  &__intup {
    background: none;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    padding: 10px 20px;
    position: relative;
    width: 100%;
    min-height: 38px;
  }

  & .field_text {
    color: inherit;
    width: 90%;
  }

  &__wrap {
    font-size: 14px;
    color: #000;
    padding: 10px;
    position: absolute;
    border: solid 1px #e5e5e5;
    width: 100%;
    top: 45px;
    z-index: 15;
    background: #fff;
    overflow-y: auto;
    transition: 0.5s;
    max-height: 0;
    opacity: 0;
    visibility: hidden;

    &-active {
      max-height: 150px;
      transition: 0.5s;
      opacity: 1;
      visibility: visible;
    }
    &_item {
      padding: 5px 10px;
      font-weight: 500;
      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }
      &:hover {
        color: $main-color;
        cursor: pointer;
      }
    }

    &-disabled {
      color: #8f9592 !important;
      cursor: default !important;
    }
  }
}

.field {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__icon,
  .field__icon_click {
    height: 100%;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_box {
    height: 26px;
    display: block;
    margin: 0 auto;
  }

  &_wrap {
    padding-top: 12px;
    transform: rotateX(180deg);
    height: 40px;
    display: block;
    margin: 0 auto;
  }
}

.field__icon_click {
  z-index: 10;
}

.field {
  & .field__icon_wrap {
    transform: rotateX(0deg);
    width: 30%;
    height: 100%;
    transition: 0.3s;
  }
}
.field-active {
  & .field__icon_wrap {
    transform: rotateX(-180deg);
    width: 30%;
    height: 100%;
    transition: 0.3s;
  }
}
.form_send_user_ok {
  display: inline-block;
  max-width: 400px;
  padding: 20px;

  & .fa-check-circle {
    margin: 20px 0;
    height: 70px;
    width: 100%;
    text-align: center;
  }
  &-title {
    text-align: center;
  }
}

@media (max-width: 1400px) {
  .thank_you {
    &__wrap {
      padding: 110px 0;
    }
  }
}
@media (max-width: 992px) {
  .thank_you {
    padding: 0 10px;
    &__wrap {
      padding: 90px 0;
    }
  }
}
// noUi-handle-upper
// .noUi-handle noUi-handle-lower
