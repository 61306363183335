// main: ../style.sass

.main_slider {
  &_control {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
  }
  outline: none;
  &_box {
    outline: none;
  }
  &_title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.13;
    color: #2f3134;
  }

  &_main {
    font-size: 16px;
    line-height: 1.63;
    text-align: justify;
    color: #95999d;
    margin: 40px 0 20px;
  }

  &_btn {
    width: 195px;
  }

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_section {
    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 65%;
    }
  }

  &_animation {
    width: 100%;

    & video {
      width: 100%;
    }
  }
}

@media (max-width: 1400px) {
  // font-size: 50px;
}
@media (max-width: 1366px) {
  .main_slider {
    &_title {
      font-size: 36px;
    }

    &_section {
      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 60%;
      }
    }
  }
}

@media (max-width: 1100px) {
  .main_slider {
    &_title {
      font-size: 32px;
    }
  }
}

@media (max-width: 992px) {
  .main_slider {
    &_info {
    }

    &_title {
      font-size: 45px;
    }

    .slick-track {
      display: flex;
    }

    &_box {
      height: auto;
    }

    &_item {
      flex-direction: column-reverse;
      height: 100%;
    }

    &_section {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        height: 100%;
      }

      &:nth-child(2) {
        width: 70%;
      }
    }

    &_btn {
      margin: 0 auto;
    }
  }
}
@media (max-width: 768px) {
  .main_slider {
    &_section {
      &:nth-child(1) {
        width: 90%;
      }

      &:nth-child(2) {
        width: 90%;
      }
    }

    &_main {
      margin: 20px 0 15px;
    }

    &_btn {
      margin: 0 auto;
    }
  }
}
@media (max-width: 600px) {
  .main_slider {
    &_control {
      margin: 20px 0;
    }
    &_title {
      font-size: 33px;
    }
  }
}
@media (max-width: 440px) {
  .main_slider {
    &_title {
      font-size: 29px;
    }
  }
}
@media (max-width: 380px) {
  .main_slider {
    &_title {
      font-size: 25px;
    }
  }
}
.slick-track {
  outline: none;
  &:focus {
    outline: none;
  }
}
